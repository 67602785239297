// Import statements at the very top
import React, { useEffect, useState, useContext } from 'react';
import './ToolPropertiesPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faMinus,
  faSave,
  faFileDownload,
  faFileUpload,
  faFileExport,
  faBold,
  faItalic,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import HeaderImage from '../../assets/Images/Header.png';
import FacebookIcon from '../../assets/Images/icons/facebook.svg';
import DiscordIcon from '../../assets/Images/icons/discord.svg';
import YoutubeIcon from '../../assets/Images/icons/youtube.svg';
import { UserContext } from '../../context/UserContext';
import { faImages } from '@fortawesome/free-solid-svg-icons';


// ✅ Now you can safely run executable code like this:
library.add(faBold, faItalic, faAlignLeft, faAlignCenter, faAlignRight);


const shapeSVGs = {
  rect: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="16" height="16" />
    </svg>
  ),
  roundedRect: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="3" width="18" height="18" rx="4" ry="4" />
    </svg>
  ),
  circle: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  ),
  triangle: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="12,2 22,20 2,20" />
    </svg>
  ),
  star: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="12,2 15,8.5 22,9.3 17,14 18.5,21 12,17.3 5.5,21 7,14 2,9.3 9,8.5" />
    </svg>
  ),
  pentagon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="12,2 19,7 17,19 7,19 5,7" />
    </svg>
  ),
  hexagon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="12,2 19,7 19,17 12,22 5,17 5,7" />
    </svg>
  ),
  octagon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="7,2 17,2 22,7 22,17 17,22 7,22 2,17 2,7" />
    </svg>
  ),
};

const ToolPropertiesPanel = ({
  isPropertiesCollapsed,
  togglePropertiesPanel,
  selectedShapeIds,
  allSelectedAreText,
  allSelectedAreShapes,
  allSelectedAreLines,
  allSelectedArePencilLines, // Newly added prop
  shapeProperties,
  updateShapePropertiesHandler,
  setShapeProperties,
  textProperties,
  updateTextPropertiesHandler,
  selectedTool,
  setSelectedShapeType,
  selectedShapeType,
  changeCanvasOrientation,
  pages,
  activePage,
  changeMood,
  handleZoomIn,
  handleZoomOut,
  updateLinePropertiesHandler,
  lineProperties,
  updatePencilPropertiesHandler,
  pencilProperties,
  addBlankPage,
  duplicateCurrentPage,
  saveState,
  exportToPDF,
  triggerFileInput,
  triggerImageUpload,
  saveStateAsJSON,
  isGridVisible,
  setIsGridVisible,
  isQuartersGridVisible,
  setIsQuartersGridVisible,
  isThirdsGridVisible,
  setIsThirdsGridVisible,
  exportFilename,
  setExportFilename,
  allSelectedAreImages,
  setShowImageLibraryModal,
  onSetSquareLogo,
  onSetLogo,
  onUploadTemplateClick,
  user,
}) => {
  // Optional: Debugging to verify the shape type
  useEffect(() => {
    console.log('Selected Shape Type:', shapeProperties.type);
  }, [shapeProperties.type]);
  
  
  const allStrokeEnabled = shapeProperties.strokeEnabled;
  const allFillEnabled = shapeProperties.fillEnabled;
  const logoSrc = `${process.env.PUBLIC_URL}/assets/Images/Logo.png`;  
  const { currentUser } = useContext(UserContext);
  // Handlers for Shape Opacity
  const handleShapeOpacityChange = (e) => {
    const value = parseFloat(e.target.value);
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'opacity');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        opacity: value,
      }));
    }
  };
  
  // Handlers for Shape Stroke Width
  const handleShapeStrokeWidthChange = (e) => {
    const value = parseFloat(e.target.value);
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'strokeWidth');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        strokeWidth: value,
      }));
    }
  };

  // Handlers for Shape Stroke Color
  const handleShapeStrokeColorChange = (e) => {
    const value = e.target.value;
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'strokeColor');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        strokeColor: value,
      }));
    }
  };

  // Handlers for Shape Fill Color
  const handleShapeFillColorChange = (e) => {
    const value = e.target.value;
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'fillColor');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        fillColor: value,
      }));
    }
  };

  // New Handlers for Shape Stroke Width and Dashed Line
  const handleShapeStrokeWidth = (e) => {
    const value = parseFloat(e.target.value);
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'strokeWidth');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        strokeWidth: value,
      }));
    }
  };

  const handleShapeDashEnabled = (e) => {
    const checked = e.target.checked;
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'dashEnabled');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        dashEnabled: checked,
      }));
    }
  };

  const handleShapeDashPatternChange = (e) => {
    const value = e.target.value.split(',').map(Number);
    if (selectedShapeIds.length > 0) {
      updateShapePropertiesHandler(e, 'dash');
    } else {
      setShapeProperties((prevProps) => ({
        ...prevProps,
        dash: value,
      }));
    }
  };
  

  return (
    <div className={`tool-properties ${isPropertiesCollapsed ? 'collapsed' : ''}`}>
      <div className="collapse-button" onClick={togglePropertiesPanel}>
        <FontAwesomeIcon
          icon={isPropertiesCollapsed ? faChevronLeft : faChevronRight}
        />
      </div>
      {!isPropertiesCollapsed && (
        <div className="properties-content">
          {selectedShapeIds.length > 0 ? (
            <>
              {allSelectedAreImages ? (
                <div className="image-tool">
                  {/* Opacity Slider for Images */}
                  <div className="property-group">
                    <h3 className="property-heading">Opacity:</h3>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={shapeProperties.opacity}
                      onChange={handleShapeOpacityChange}
                      className="opacity-slider"
                      onMouseUp={handleShapeOpacityChange} // Push to Undo stack on mouse up
                    />
                  </div>
                </div>
              ) : allSelectedAreText ? (
                <div className="text-tool">
                  {/* Text Properties */}
                  <div className="property-group">
                    <h3 className="property-heading">Text Properties</h3>
                  </div>

                  <div className="property-group">
                  <textarea
                    value={textProperties.text}
                    onChange={(e) => updateTextPropertiesHandler(e, 'text')}
                    placeholder="Edit text here"
                    className="text-input export-filename-input multiline-text-input"
                  />
                  </div>

                  <div className="property-group">
                    <h3 className="property-heading">Alignment & Style:</h3>
                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                      <button
                        className={`shape-button ${textProperties.align === 'left' ? 'active' : ''}`}
                        onClick={() => updateTextPropertiesHandler({ target: { value: 'left' } }, 'align')}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-align-left" />
                      </button>
                      <button
                        className={`shape-button ${textProperties.align === 'center' ? 'active' : ''}`}
                        onClick={() => updateTextPropertiesHandler({ target: { value: 'center' } }, 'align')}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-align-center" />
                      </button>
                      <button
                        className={`shape-button ${textProperties.align === 'right' ? 'active' : ''}`}
                        onClick={() => updateTextPropertiesHandler({ target: { value: 'right' } }, 'align')}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-align-right" />
                      </button>
                      <button
                        className={`shape-button ${textProperties.bold ? 'active' : ''}`}
                        onClick={(e) => updateTextPropertiesHandler({ target: { checked: !textProperties.bold } }, 'bold')}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-bold" />
                      </button>
                      <button
                        className={`shape-button ${textProperties.italic ? 'active' : ''}`}
                        onClick={(e) => updateTextPropertiesHandler({ target: { checked: !textProperties.italic } }, 'italic')}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-italic" />
                      </button>
                    </div>
                  </div>


                  <div className="property-group">
                    <h3 className="property-heading">Font Family:</h3>
                    <select
                      value={textProperties.fontFamily}
                      onChange={(e) => updateTextPropertiesHandler(e, 'fontFamily')}
                      className="select-input"
                    >
                      <option value="Arial">Arial</option>
                      <option value="Helvetica">Helvetica</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Verdana">Verdana</option>
                      <option value="edugroove-hand">EDUGroove Hand</option>
                      <option value="edugroove-hand-arrows">EDUGroove Hand Arrows</option>
                      <option value="edugroove-dots">EDUGroove Dots</option>                  
                      <option value="edugroove-happy">EDUGroove Happy</option>
                      <option value="LettersForLearners">Letters For Learners</option>
                      <option value="LettersForLearnersDots">Letters For Learners Dots</option>
                      <option value="LettersForLearnersLined">Letters For Learners Lined</option>
                      <option value="LettersForLearnersLinedDots">Letters For Learners Lined Dots</option>
                      <option value="EduAUVICWANTArrows-VariableFont_wght">Edu AUS Arrows</option>
                      <option value="EduAUVICWANTDots-VariableFont_wght">Edu AUS Dots</option>
                      <option value="EduAUVICWANTHand-VariableFont_wght">Edu AUS Hand</option>
                      <option value="LondrinaOutline-Regular">Londrina Colouring</option>
                      <option value="BungeeOutline-Regular">Bungee Colouring</option>                                        
                    </select>
                  </div>

                  <div className="property-group">
                    <h3 className="property-heading">Font Size:</h3>
                    <input
                      type="number"
                      min="0"
                      value={textProperties.fontSize}
                      onChange={(e) => updateTextPropertiesHandler(e, 'fontSize')}
                      className="number-input"
                    />
                  </div>

                  <div className="property-group">
                    <h3 className="property-heading">Text Color:</h3>
                    <input
                      type="color"
                      value={textProperties.fill}
                      onChange={(e) => updateTextPropertiesHandler(e, 'fill')}
                      className="color-input"
                    />
                  </div>

                  <div className="property-group">
                    <h3 className="property-heading">Opacity:</h3>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={textProperties.opacity}
                      onChange={(e) => updateTextPropertiesHandler(e, 'opacity')}
                      className="opacity-slider"
                      onMouseUp={(e) => updateTextPropertiesHandler(e, 'opacity')} // Push to Undo stack on mouse up
                    />
                  </div>
                  
                </div>
              ) : allSelectedAreShapes ? (
                <div className="shape-tool">
                  {/* Conditionally render Corner Radius only for Rounded Rectangles */}
                  {shapeProperties.type === 'roundedRect' && (
                    <div className="property-group">
                      <h3 className="property-heading">Corner Radius:</h3>
                      <input
                        type="number"
                        min="0"
                        value={shapeProperties.cornerRadius}
                        onChange={(e) => updateShapePropertiesHandler(e, 'cornerRadius')}
                        placeholder="Corner Radius"
                        className="number-input"
                      />
                    </div>
                  )}

                  {/* Stroke Width Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Width:</h3>
                    <input
                      type="number"
                      min="0"
                      value={shapeProperties.strokeWidth}
                      onChange={handleShapeStrokeWidthChange}
                      className="number-input"
                    />
                  </div>

                  {/* Stroke Color Property */}
                  <div className="property-group">
                    <h3 className="property-heading">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={allStrokeEnabled}
                          onChange={(e) => {
                            updateShapePropertiesHandler(e, 'strokeEnabled');
                          }}
                        />
                        Stroke Color:
                      </label>
                    </h3>
                    <input
                      type="color"
                      value={shapeProperties.strokeColor || '#000000'}
                      onChange={handleShapeStrokeColorChange}
                      className="color-input"
                      disabled={!allStrokeEnabled}
                    />
                  </div>

                  {/* Fill Color Property */}
                  <div className="property-group">
                    <h3 className="property-heading">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          checked={allFillEnabled}
                          onChange={(e) => {
                            updateShapePropertiesHandler(e, 'fillEnabled');
                          }}
                        />
                        Fill Color:
                      </label>
                    </h3>
                    <input
                      type="color"
                      value={shapeProperties.fillColor || '#FFFFFF'}
                      onChange={handleShapeFillColorChange}
                      className="color-input"
                      disabled={!allFillEnabled}
                    />
                  </div>


                  {/* Opacity Slider */}
                  <div className="property-group">
                    <h3 className="property-heading">Opacity:</h3>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={shapeProperties.opacity}
                      onChange={handleShapeOpacityChange}
                      className="opacity-slider"
                      onMouseUp={handleShapeOpacityChange} // Push to Undo stack on mouse up
                    />
                  </div>

                  {/* Dashed Line Controls */}
                  <div className="checkbox-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={shapeProperties.dashEnabled}
                        onChange={(e) => {
                          setShapeProperties({
                            ...shapeProperties,
                            dashEnabled: e.target.checked,
                          });
                          updateShapePropertiesHandler(e, 'dashEnabled');
                        }}
                      />
                      Dashed Line
                    </label>
                  </div>

                  {/* Dash Pattern Heading */}
                  {shapeProperties.dashEnabled && (
                    <div className="property-group">
                      <h3 className="property-heading">Dash Pattern:</h3>
                    </div>
                  )}

                  {/* Dash Pattern Input */}
                  {shapeProperties.dashEnabled && (
                    <div className="property-group">
                      <input
                        type="text"
                        value={shapeProperties.dash.join(',')}
                        onChange={(e) => updateShapePropertiesHandler(e, 'dash')}
                        className="text-input"
                        placeholder="e.g., 10,5"
                      />
                    </div>
                  )}
                </div>
              ) : allSelectedAreLines ? (
                <div className="line-tool">
                  {/* Stroke Width Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Width:</h3>
                    <input
                      type="number"
                      value={lineProperties.strokeWidth}
                      onChange={(e) => updateLinePropertiesHandler(e, 'strokeWidth')}
                      className="number-input"
                    />
                  </div>

                  {/* Stroke Color Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Color:</h3>
                    <input
                      type="color"
                      value={lineProperties.strokeColor}
                      onChange={(e) => updateLinePropertiesHandler(e, 'strokeColor')}
                      className="color-input"
                    />
                  </div>

                  {/* Dashed Line Checkbox */}
                  <div className="checkbox-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={lineProperties.dashEnabled}
                        onChange={(e) => updateLinePropertiesHandler(e, 'dashEnabled')}
                      />
                      Dashed Line
                    </label>
                  </div>

                  {/* Dash Pattern Heading */}
                  {lineProperties.dashEnabled && (
                    <div className="property-group">
                      <h3 className="property-heading">Dash Pattern:</h3>
                    </div>
                  )}

                  {/* Dash Pattern Input */}
                  {lineProperties.dashEnabled && (
                    <div className="property-group">
                      <input
                        type="text"
                        value={lineProperties.dash.join(',')}
                        onChange={(e) => updateLinePropertiesHandler(e, 'dash')}
                        className="text-input"
                        placeholder="e.g., 10,5"
                      />
                    </div>
                  )}
                </div>
              ) : allSelectedArePencilLines ? (
                <div className="pencil-tool">
                  {/* Stroke Width Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Width:</h3>
                    <input
                      type="number"
                      value={pencilProperties.strokeWidth}
                      onChange={(e) => updatePencilPropertiesHandler(e, 'strokeWidth')}
                      className="number-input"
                    />
                  </div>

                  {/* Smoothness (Tension) Slider */}
                  <div className="property-group">
                    <h3 className="property-heading">Smoothness:</h3>
                    <input
                      type="range"
                      min="0.1"
                      max="1"
                      step="0.05"
                      value={pencilProperties.tension}
                      onChange={(e) => updatePencilPropertiesHandler(e, 'tension')}
                      className="opacity-slider"
                    />
                  </div>

                  {/* Stroke Color Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Color:</h3>
                    <input
                      type="color"
                      value={pencilProperties.strokeColor}
                      onChange={(e) => updatePencilPropertiesHandler(e, 'strokeColor')}
                      className="color-input"
                    />
                  </div>

                  {/* Pencil Opacity */}
                  <div className="property-group">
                    <h3 className="property-heading">Opacity:</h3>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={pencilProperties.opacity}
                      onChange={(e) => updatePencilPropertiesHandler(e, 'opacity')}
                      className="opacity-slider"
                    />
                  </div>

                 
                  
                  {/* Dashed Line Checkbox */}
                  <div className="checkbox-group">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={pencilProperties.dashEnabled}
                        onChange={(e) => updatePencilPropertiesHandler(e, 'dashEnabled')}
                      />
                      Dashed Line
                    </label>
                  </div>

                  {/* Dash Pattern Heading */}
                  {pencilProperties.dashEnabled && (
                    <div className="property-group">
                      <h3 className="property-heading">Dash Pattern:</h3>
                    </div>
                  )}

                  {/* Dash Pattern Input */}
                  {pencilProperties.dashEnabled && (
                    <div className="property-group">
                      <input
                        type="text"
                        value={pencilProperties.dash.join(',')}
                        onChange={(e) => updatePencilPropertiesHandler(e, 'dash')}
                        className="text-input"
                        placeholder="e.g., 10,5"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="mixed-shapes-tool">
                  <p>Selected shapes have mixed types. Editing is limited to common properties.</p>
                  {/* Common Properties */}
                  {/* Stroke Width Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Width:</h3>
                    <input
                      type="number"
                      value={shapeProperties.strokeWidth}
                      onChange={(e) => updateShapePropertiesHandler(e, 'strokeWidth')}
                      className="number-input"
                    />
                  </div>

                  {/* Stroke Color Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Stroke Color:</h3>
                    <input
                      type="color"
                      value={shapeProperties.strokeColor}
                      onChange={handleShapeStrokeColorChange}
                      className="color-input"
                    />
                  </div>

                  {/* Fill Color Property */}
                  <div className="property-group">
                    <h3 className="property-heading">Fill Color:</h3>
                    <input
                      type="color"
                      value={shapeProperties.fillColor}
                      onChange={handleShapeFillColorChange}
                      className="color-input"
                    />
                  </div>

                  {/* Opacity Slider */}
                  <div className="property-group">
                    <h3 className="property-heading">Opacity:</h3>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={shapeProperties.opacity}
                      onChange={handleShapeOpacityChange}
                      className="opacity-slider"
                      onMouseUp={handleShapeOpacityChange} // Push to Undo stack on mouse up
                    />
                  </div>

                  {/* Pencil Opacity */}
                  <label className="property-label">Opacity</label>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={pencilProperties.opacity}
                    onChange={(e) => updatePencilPropertiesHandler(e, 'opacity')}
                  />
                  

                  {/* Dashed Line Controls */}
                  <div className="checkbox-group" style={{ marginTop: '10px' }}>
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={shapeProperties.dashEnabled}
                        onChange={(e) => {
                          updateShapePropertiesHandler(e, 'dashEnabled');
                        }}
                      />
                      Dashed Line
                    </label>
                  </div>

                  {/* Dash Pattern Heading */}
                  {shapeProperties.dashEnabled && (
                    <div className="property-group">
                      <h3 className="property-heading">Dash Pattern:</h3>
                    </div>
                  )}

                  {/* Dash Pattern Input */}
                  {shapeProperties.dashEnabled && (
                    <div className="property-group">
                      <input
                        type="text"
                        value={shapeProperties.dash.join(',')}
                        onChange={(e) => updateShapePropertiesHandler(e, 'dash')}
                        className="text-input"
                        placeholder="e.g., 10,5"
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : selectedTool === 'text' ? (
            <div className="text-tool">
              {/* Text Properties */}
              <div className="property-group">
                <h3 className="property-heading">Text Properties</h3>
              </div>

              <div className="property-group">
              <textarea
                value={textProperties.text}
                onChange={(e) => updateTextPropertiesHandler(e, 'text')}
                placeholder="Edit text here"
                className="text-input export-filename-input multiline-text-input"
              />
              </div>            

              <div className="property-group">
                <h3 className="property-heading">Alignment & Style:</h3>
                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                  <button
                    className={`shape-button ${textProperties.align === 'left' ? 'active' : ''}`}
                    onClick={() => updateTextPropertiesHandler({ target: { value: 'left' } }, 'align')}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-align-left" />
                  </button>
                  <button
                    className={`shape-button ${textProperties.align === 'center' ? 'active' : ''}`}
                    onClick={() => updateTextPropertiesHandler({ target: { value: 'center' } }, 'align')}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-align-center" />
                  </button>
                  <button
                    className={`shape-button ${textProperties.align === 'right' ? 'active' : ''}`}
                    onClick={() => updateTextPropertiesHandler({ target: { value: 'right' } }, 'align')}
                  >
                    <FontAwesomeIcon icon="fa-solid fa-align-right" />
                  </button>
                  <button
                    className={`shape-button ${textProperties.bold ? 'active' : ''}`}
                    onClick={() =>
                      updateTextPropertiesHandler({ target: { checked: !textProperties.bold } }, 'bold')
                    }
                  >
                    <FontAwesomeIcon icon="fa-solid fa-bold" />
                  </button>
                  <button
                    className={`shape-button ${textProperties.italic ? 'active' : ''}`}
                    onClick={() =>
                      updateTextPropertiesHandler({ target: { checked: !textProperties.italic } }, 'italic')
                    }
                  >
                    <FontAwesomeIcon icon="fa-solid fa-italic" />
                  </button>
                </div>
              </div>



              <div className="property-group">
                <h3 className="property-heading">Font Family:</h3>
                <select
                  value={textProperties.fontFamily}
                  onChange={(e) => updateTextPropertiesHandler(e, 'fontFamily')}
                  className="select-input"
                >
                  <option value="Arial">Arial</option>
                  <option value="Helvetica">Helvetica</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Verdana">Verdana</option>
                  <option value="edugroove-hand">EDUGroove Hand</option>
                  <option value="edugroove-hand-arrows">EDUGroove Hand Arrows</option>
                  <option value="edugroove-dots">EDUGroove Dots</option>                  
                  <option value="edugroove-happy">EDUGroove Happy</option>
                  <option value="LettersForLearners">Letters For Learners</option>
                  <option value="LettersForLearnersDots">Letters For Learners Dots</option>
                  <option value="LettersForLearnersLined">Letters For Learners Lined</option>
                  <option value="LettersForLearnersLinedDots">Letters For Learners Lined Dots</option>
                  <option value="EduAUVICWANTArrows-VariableFont_wght">Edu AUS Arrows</option>
                  <option value="EduAUVICWANTDots-VariableFont_wght">Edu AUS Dots</option>
                  <option value="EduAUVICWANTHand-VariableFont_wght">Edu AUS Hand</option>
                  <option value="LondrinaOutline-Regular">Londrina Colouring</option>
                  <option value="BungeeOutline-Regular">Bungee Colouring</option>                  
                </select>
              </div>

              <div className="property-group">
                <h3 className="property-heading">Font Size:</h3>
                <input
                  type="number"
                  value={textProperties.fontSize}
                  onChange={(e) => updateTextPropertiesHandler(e, 'fontSize')}
                  className="number-input"
                />
              </div>

              <div className="property-group">
                <h3 className="property-heading">Text Color:</h3>
                <input
                  type="color"
                  value={textProperties.fill}
                  onChange={(e) => updateTextPropertiesHandler(e, 'fill')}
                  className="color-input"
                />
              </div>

              <div className="property-group">
                <h3 className="property-heading">Opacity:</h3>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={textProperties.opacity}
                  onChange={(e) => updateTextPropertiesHandler(e, 'opacity')}
                  className="opacity-slider"
                  onMouseUp={(e) => updateTextPropertiesHandler(e, 'opacity')} // Push to Undo stack on mouse up
                />
              </div>

              
            </div>
          ) : selectedTool === 'addImage' ? (
            <div className="image-tool-properties">
              <button className="upload-button" onClick={triggerImageUpload}>
                <FontAwesomeIcon icon={faFileUpload} /> Upload
              </button>
              <button className="library-button" onClick={() => setShowImageLibraryModal(true)}>
                <FontAwesomeIcon icon={faImages} /> Image Library
              </button>
        
              {/* Only show Insert Logo if user is admin */}
              {currentUser?.isAdmin && (
                <>
                  <h3>Insert Logo</h3>
                    <button onClick={() => onSetLogo()}>
                      Set Rectangle Logo
                    </button>
                    <button onClick={() => onSetSquareLogo()}>
                      Set Square Logo
                    </button>
                </>
              )}
            </div>
          ) : selectedTool === 'save' ? (
            <div className="save-tool">
              <div className="property-group">
                <input
                  type="text"
                  value={exportFilename}
                  onChange={(e) => setExportFilename(e.target.value)}
                  className="text-input"
                  placeholder="Enter file name"
                />
              </div>

              {currentUser?.isAdmin && (
                <button className="save-button" onClick={onUploadTemplateClick}>
                Upload Template
              </button>
            )}
              {currentUser && (          
              <button className="save-button" onClick={saveStateAsJSON}>
                <FontAwesomeIcon icon={faFileDownload} /> Download to Comp
              </button>
              )}
              {currentUser && (
              <button className="save-button" onClick={triggerFileInput}>
                <FontAwesomeIcon icon={faFileUpload} /> Load from Comp
              </button>
              )}
              <button className="save-button" onClick={exportToPDF}>
                <FontAwesomeIcon icon={faFileExport} /> Export to PDF
              </button>
            </div>
          ) : selectedTool === 'shape' ? (
            <div className="shape-tool-section">
              <div className="shape-selection">
                {Object.keys(shapeSVGs).map((shape) => (
                  <button
                    key={shape}
                    onClick={() => setSelectedShapeType(shape)}
                    className={selectedShapeType === shape ? 'active shape-button' : 'shape-button'}
                    aria-label={shape.charAt(0).toUpperCase() + shape.slice(1)}
                  >
                    {shapeSVGs[shape]}
                  </button>
                ))}
              </div>

              {/* Stroke Width Property */}
              <div className="property-group">
                <h3 className="property-heading">Stroke Width:</h3>
                <input
                  type="number"
                  value={shapeProperties.strokeWidth}
                  onChange={handleShapeStrokeWidth}
                  className="number-input"
                />
              </div>

              {/* Stroke Color with Checkbox */}
              <div className="property-group">
                <h3 className="property-heading">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={shapeProperties.strokeEnabled}
                      onChange={(e) =>
                        setShapeProperties((prevProps) => ({
                          ...prevProps,
                          strokeEnabled: e.target.checked,
                        }))
                      }
                    />
                    Stroke Color:
                  </label>
                </h3>
                <input
                  type="color"
                  value={shapeProperties.strokeColor || '#000000'}
                  onChange={handleShapeStrokeColorChange}
                  className="color-input"
                  disabled={!shapeProperties.strokeEnabled}
                />
              </div>

              {/* Fill Color with Checkbox */}
              <div className="property-group">
                <h3 className="property-heading">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={shapeProperties.fillEnabled}
                      onChange={(e) =>
                        setShapeProperties((prevProps) => ({
                          ...prevProps,
                          fillEnabled: e.target.checked,
                        }))
                      }
                    />
                    Fill Color:
                  </label>
                </h3>
                <input
                  type="color"
                  value={shapeProperties.fillColor || '#FFFFFF'}
                  onChange={handleShapeFillColorChange}
                  className="color-input"
                  disabled={!shapeProperties.fillEnabled}
                />
              </div>


              {/* Opacity Slider */}
              <div className="property-group">
                <h3 className="property-heading">Opacity:</h3>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={shapeProperties.opacity}
                  onChange={handleShapeOpacityChange}
                  className="opacity-slider"
                  onMouseUp={handleShapeOpacityChange} // Push to Undo stack on mouse up
                />
              </div>

              {/* Dashed Line Controls */}
              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={shapeProperties.dashEnabled}
                    onChange={handleShapeDashEnabled}
                  />
                  Dashed Line
                </label>
              </div>

              {/* Dash Pattern Heading */}
              {shapeProperties.dashEnabled && (
                <div className="property-group">
                  <h3 className="property-heading">Dash Pattern:</h3>
                </div>
              )}

              {/* Dash Pattern Input */}
              {shapeProperties.dashEnabled && (
                <div className="property-group">
                  <input
                    type="text"
                    value={shapeProperties.dash.join(',')}
                    onChange={handleShapeDashPatternChange}
                    className="text-input"
                    placeholder="e.g., 10,5"
                  />
                </div>
              )}
            </div>
          ) : selectedTool === 'line' ? (
            <div className="line-tool">
              {/* Stroke Width Property */}
              <div className="property-group">
                <h3 className="property-heading">Stroke Width:</h3>
                <input
                  type="number"
                  value={lineProperties.strokeWidth}
                  onChange={(e) => updateLinePropertiesHandler(e, 'strokeWidth')}
                  className="number-input"
                />
              </div>

              {/* Stroke Color Property */}
              <div className="property-group">
                <h3 className="property-heading">Stroke Color:</h3>
                <input
                  type="color"
                  value={lineProperties.strokeColor}
                  onChange={(e) => updateLinePropertiesHandler(e, 'strokeColor')}
                  className="color-input"
                />
              </div>

              {/* Dashed Line Checkbox */}
              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={lineProperties.dashEnabled}
                    onChange={(e) => updateLinePropertiesHandler(e, 'dashEnabled')}
                  />
                  Dashed Line
                </label>
              </div>

              {/* Dash Pattern Heading */}
              {lineProperties.dashEnabled && (
                <div className="property-group">
                  <h3 className="property-heading">Dash Pattern:</h3>
                </div>
              )}

              {/* Dash Pattern Input */}
              {lineProperties.dashEnabled && (
                <div className="property-group">
                  <input
                    type="text"
                    value={lineProperties.dash.join(',')}
                    onChange={(e) => updateLinePropertiesHandler(e, 'dash')}
                    className="text-input"
                    placeholder="e.g., 10,5"
                  />
                </div>
              )}
            </div>
          ) : selectedTool === 'pencil' ? (
            <div className="pencil-tool">
              {/* Stroke Width Property */}
              <div className="property-group">
                <h3 className="property-heading">Stroke Width:</h3>
                <input
                  type="number"
                  value={pencilProperties.strokeWidth}
                  onChange={(e) => updatePencilPropertiesHandler(e, 'strokeWidth')}
                  className="number-input"
                />
              </div>
          
              {/* Smoothness (Tension) Slider */}
              <div className="property-group">
                <h3 className="property-heading">Smoothness:</h3>
                <input
                  type="range"
                  min="0.1"
                  max="1"
                  step="0.05"
                  value={pencilProperties.tension}
                  onChange={(e) => updatePencilPropertiesHandler(e, 'tension')}
                  className="opacity-slider"
                />
              </div>
          
              {/* Stroke Color Property */}
              <div className="property-group">
                <h3 className="property-heading">Stroke Color:</h3>
                <input
                  type="color"
                  value={pencilProperties.strokeColor}
                  onChange={(e) => updatePencilPropertiesHandler(e, 'strokeColor')}
                  className="color-input"
                />
              </div>

              {/* Pencil Opacity */}
              <div className="property-group">
                <h3 className="property-heading">Opacity:</h3>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={pencilProperties.opacity}
                  onChange={(e) => updatePencilPropertiesHandler(e, 'opacity')}
                  className="opacity-slider"
                />
              </div>


          
              {/* Dashed Line Checkbox */}
              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={pencilProperties.dashEnabled}
                    onChange={(e) => updatePencilPropertiesHandler(e, 'dashEnabled')}
                  />
                  Dashed Line
                </label>
              </div>
          
              {/* Dash Pattern Input */}
              {pencilProperties.dashEnabled && (
                <>
                  <div className="property-group">
                    <h3 className="property-heading">Dash Pattern:</h3>
                  </div>
                  <div className="property-group">
                    <input
                      type="text"
                      value={pencilProperties.dash.join(',')}
                      onChange={(e) => updatePencilPropertiesHandler(e, 'dash')}
                      className="text-input"
                      placeholder="e.g., 10,5"
                    />
                  </div>
                </>
              )}
            </div>
          
          ) : selectedTool === 'workspaceInfo' ? (
            <div className="workspace-orientation">
              {/* Canvas Orientation */}
              <div className="canvas-orientation">
                <h3>Canvas Orientation</h3>
                <div className="orientation-selection">
                  <button
                    onClick={() => changeCanvasOrientation('portrait')}
                    className={
                      pages[activePage].orientation === 'portrait'
                        ? 'active orientation-button'
                        : 'orientation-button'
                    }
                    aria-label="Portrait Orientation"
                  >
                    <div className="orientation-icon portrait"></div>
                    Portrait
                  </button>
                  <button
                    onClick={() => changeCanvasOrientation('landscape')}
                    className={
                      pages[activePage].orientation === 'landscape'
                        ? 'active orientation-button'
                        : 'orientation-button'
                    }
                    aria-label="Landscape Orientation"
                  >
                    <div className="orientation-icon landscape"></div>
                    Landscape
                  </button>
                </div>
              </div>

              {/* Grid Settings */}
              <div className="grid-settings">
                <h3>Grid Settings</h3>

                {/* Simple Grid Checkbox */}
                <div className="property-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={isGridVisible}
                      onChange={(e) => {
                        console.log('Simple Grid toggled:', e.target.checked);
                        setIsGridVisible(e.target.checked);
                      }}
                    />
                    Simple Grid
                  </label>
                </div>

                {/* Quarters Grid Checkbox */}
                <div className="property-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={isQuartersGridVisible}
                      onChange={(e) => {
                        console.log('Quarters Grid toggled:', e.target.checked);
                        setIsQuartersGridVisible(e.target.checked);
                      }}
                    />
                    Quarters Grid
                  </label>
                </div>

                {/* Thirds Grid Checkbox */}
                <div className="property-group">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={isThirdsGridVisible}
                      onChange={(e) => {
                        console.log('Thirds Grid toggled:', e.target.checked);
                        setIsThirdsGridVisible(e.target.checked);
                      }}
                    />
                    Thirds Grid
                  </label>
                </div>
              </div>

              {/* Workspace Mood */}
              <div className="workspace-info">
                <h3>Workspace Mood</h3>
                <div className="property-group">
                  <h3 className="property-heading">Mood:</h3>
                  <select onChange={(e) => changeMood(e.target.value)} className="select-input">
                    <option value="Crafty">Crafty</option>                    
                    <option value="Calm">Calm</option>
                    <option value="Energetic">Energetic</option>
                    <option value="Creative">Creative</option>                    
                    <option value="Workbench">Workbench</option>
                    <option value="Default">Retro</option>
                  </select>
                </div>
              </div>
            </div>
          ) : selectedTool === 'zoom' ? (
            <div className="zoom-tool">
              <button className="add-page-button zoom-button" onClick={handleZoomIn} aria-label="Zoom In">
                <FontAwesomeIcon icon={faPlus} />
              </button>
              <button className="add-page-button zoom-button" onClick={handleZoomOut} aria-label="Zoom Out">
                <FontAwesomeIcon icon={faMinus} />
              </button>
            </div>
          ) : selectedTool === 'addPage' ? (
            <div className="add-page-tool">
              <button className="add-page-button" onClick={addBlankPage}>
                <FontAwesomeIcon icon={faPlus} /> Add Blank Page
              </button>
              <button className="add-page-button" onClick={duplicateCurrentPage}>
                <FontAwesomeIcon icon={faPlus} /> Duplicate Page
              </button>
            </div>
          ) : selectedTool ? (
            <div>
              {/* Additional tools can be added here */}
            </div>
          ) : (
            <div>
              {/* Default content when no tool is selected */}
            </div>
          )}

          {/* Community Section */}
          <div className="community-section">
          <img
            src={HeaderImage}
            alt="EDU Groove"
            className="tool-header-logo"
          />
        <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61569095273653" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <img src={FacebookIcon} alt="Facebook" className="social-icon" />
            </a>            
            <a href="https://discord.gg/pHKCACsq5h" target="_blank" rel="noopener noreferrer" aria-label="Discord">
              <img src={DiscordIcon} alt="Discord" className="social-icon" />
            </a>
            <a href="https://www.youtube.com/@EDUGrooveHub" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              <img src={YoutubeIcon} alt="YouTube" className="social-icon" />
            </a>
          </div>
      </div>
        </div>
      )}
    </div>
  );
};



export default ToolPropertiesPanel;
