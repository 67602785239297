// MainApp.js

import React, { useState, useRef, useEffect } from 'react';
import './MainApp.css';

// Import components
import TopMenuBar from '../TopMenuBar/TopMenuBar';
import Sidebar from '../Sidebar/Sidebar';
import CanvasArea from '../CanvasArea/CanvasArea';
import ToolPropertiesPanel from '../ToolPropertiesPanel/ToolPropertiesPanel';
import PageTabs from '../PageTabs/PageTabs';
import DeleteModal from '../DeleteModal/DeleteModal';
import LastPageModal from '../LastPageModal/LastPageModal';
import ImageLibraryModal from '../ImageLibraryModal/ImageLibraryModal';
import UploadTemplateModal from '../UploadTemplateModal/UploadTemplateModal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';
import '../../Styles/fonts.css'; // Adjust the path based on your actual file structure
// Import background images
import calmImage from '../../assets/Images/calm.webp';
import creativeImage from '../../assets/Images/creative.webp';
import energeticImage from '../../assets/Images/energetic.webp';
import craftyImage from '../../assets/Images/crafty.webp';
import workbenchImage from '../../assets/Images/workbench.webp';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import LottieLoader from '../LoadingSpinner/LottieLoader';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
  faCopy,
  faPaste,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';



const MainApp = () => {
  const location = useLocation();
  const initialState = location.state?.initialState;

  // State variables

  const { currentUser } = useContext(UserContext);
  const [logoUrl, setLogoUrl] = useState(null);
  const [squareLogoUrl, setSquareLogoUrl] = useState(null); // Add this line
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [lastCopySource, setLastCopySource] = useState(null); // 'internal' or 'external'

  const setLogoPlaceholder = () => {
    setPages((prevPages) => {
        const updatedPages = prevPages.map((page, index) =>
            index === activePage
                ? { ...page, shapes: [...page.shapes, {
                    id: Date.now().toString(),
                    type: 'image',
                    src: '__USER_LOGO__',  // ✅ Placeholder reference
                    x: 100,
                    y: 100,
                    width: 600,
                    height: 150,
                    opacity: 1,
                }] }
                : page
        );
        return updatedPages;
    });

    setSelectedShapeIds([Date.now().toString()]);
    setSelectedTool('select');
};

    const setSquareLogoPlaceholder = () => {
      const newId = Date.now().toString();

      const newShape = {
        id: newId,
        type: 'image',
        src: '__USER_SQUARE_LOGO__',
        x: 100,
        y: 100,
        width: 200,
        height: 200,
        opacity: 1,
      };

      setPages((prevPages) => {
        const updatedPages = prevPages.map((page, index) =>
          index === activePage
            ? { ...page, shapes: [...page.shapes, newShape] }
            : page
        );
        return updatedPages;
      });

      setSelectedShapeIds([newId]);
      setSelectedTool('select');
    };



  const [showImageLibraryModal, setShowImageLibraryModal] = useState(false);

  const addImageToCanvas = (image) => {
    const img = new window.Image();
    img.src = image.src;
  
    img.onload = () => {
      const newShape = {
        id: Date.now().toString(),
        type: 'image',
        src: image.src,
        x: 100, // Default x position
        y: 100, // Default y position
        width: img.width, // Use the loaded image's dimensions
        height: img.height,
        opacity: 1,
      };
  
      const updatedPages = [...pages];
      updatedPages[activePage].shapes.push(newShape);
  
      setPages(updatedPages);
      setSelectedShapeIds([newShape.id]);
      setSelectedTool('select'); // Switch to the selection tool
    };
  
    img.onerror = () => {
      console.error('Error loading image:', img.src);
    };
  };
  

  const handleImageSelect = (image) => {
    addImageToCanvas(image);
    setShowImageLibraryModal(false);
  };

  // Define the copyToAllPages function
const copyToAllPages = () => {
  if (selectedShapeIds.length === 0) return;

  // Identify which selected shapes are locked and which are not
  const lockedShapes = pages[activePage].shapes.filter(
    (shape) =>
      selectedShapeIds.includes(shape.id.toString()) && shape.locked
  );
  const unlockedShapes = pages[activePage].shapes.filter(
    (shape) =>
      selectedShapeIds.includes(shape.id.toString()) && !shape.locked
  );

  // If all selected shapes are locked, notify the user and prevent copying
  if (lockedShapes.length === selectedShapeIds.length) {
    alert('Cannot copy selected shapes because they are locked.');
    return;
  }

  // If some selected shapes are locked, notify the user that only unlocked shapes will be copied
  if (lockedShapes.length > 0) {
    alert('Some selected shapes are locked and could not be copied.');
  }

  pushToUndoStack(); // Save current state for undo functionality

  const updatedPages = pages.map((page, index) => {
    if (index === activePage) {
      // Skip the active page
      return page;
    }

    // Deep copy unlocked selected shapes
    const copiedShapes = unlockedShapes.map((shape) => {
      const newShape = JSON.parse(JSON.stringify(shape));
      newShape.id = uuidv4(); // Assign a new unique ID
      return newShape;
    });

    // Insert copied shapes at the beginning to place them behind other objects
    return {
      ...page,
      shapes: [...copiedShapes, ...page.shapes],
    };
  });

  setPages(updatedPages);
  setSelectedShapeIds([]); // Optionally, clear the selection after copying
};


  const [exportFilename, setExportFilename] = useState('');

  const [workspaceBackgroundImage, setWorkspaceBackgroundImage] = useState(craftyImage);
  const backgroundImages = {
    Calm: calmImage,
    Energetic: energeticImage,
    Creative: creativeImage,
    Crafty: craftyImage, 
    Workbench: workbenchImage,
  };

  // ✅ Preload background images on initial render
  useEffect(() => {
    Object.values(backgroundImages).forEach((img) => {
      const preloadImg = new Image();
      preloadImg.src = img;
    });
  }, []);

  // Define the toggleEditMode function
  const toggleEditMode = () => {
    setIsEditMode((prevMode) => !prevMode);
  };

  const [selectedTool, setSelectedTool] = useState('select'); // Initialize as 'select'
  const [hoveredTool, setHoveredTool] = useState(null);
  const [isPropertiesCollapsed, setIsPropertiesCollapsed] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const [scale, setScale] = useState(1);

  const [activePage, setActivePage] = useState(() => {
    if (initialState && initialState.activePage !== undefined) {
      return initialState.activePage;
    } else {
      return 0;
    }
  });

  // ✅ Ensure state is initialized before useEffect runs
const [pages, setPages] = useState(() => {
  if (initialState && initialState.pages) {
      return initialState.pages;
  } else {
      return [
          {
              id: 0,
              shapes: [],
              backgroundColor: '#ffffff',
              orientation: 'portrait',
              width: 794,
              height: 1123,
          },
      ];
  }
});

// ✅ Now `pages` is initialized before useEffect runs
useEffect(() => {
  const fetchLogo = async () => {
    let userLogo = null;
    let userSquareLogo = null;

    if (currentUser) {
      try {
        const res = await axios.get(`https://edugroove-backend.onrender.com/api/users/${currentUser.id}/logos`);

        if (res.data.logoUrl) {
          userLogo = res.data.logoUrl;
        }
        if (res.data.squareLogoUrl) {
          userSquareLogo = res.data.squareLogoUrl;
        }
      } catch (err) {
        console.warn('No user-specific logo found.');
      }
    }

    if (!userLogo) {
      try {
        const defaultRes = await axios.get('https://edugroove-backend.onrender.com/api/admin/getDefaultLogo');
        userLogo = defaultRes.data.defaultLogoUrl;
      } catch (err) {
        console.error('Error fetching default rectangular logo:', err);
      }
    }

    if (!userSquareLogo) {
      try {
        const squareDefaultRes = await axios.get('https://edugroove-backend.onrender.com/api/admin/getDefaultLogo?type=square');
        userSquareLogo = squareDefaultRes.data.defaultLogoUrl;
      } catch (err) {
        console.error('Error fetching default square logo:', err);
      }
    }

    setLogoUrl(userLogo);
    setSquareLogoUrl(userSquareLogo);
  };

  fetchLogo();
}, [currentUser]);

 // ✅ Runs when the user logs in, even as a guest



// ✅ This useEffect waits until `pages` is initialized
useEffect(() => {
  if ((logoUrl || squareLogoUrl) && pages.length > 0) {
    setPages((prevPages) => 
      prevPages.map((page) => ({
        ...page,
        shapes: page.shapes.map((shape) => {
          if (shape.type === 'image') {
            if (shape.src === '__USER_LOGO__') {
              return { ...shape, src: logoUrl };
            }
            if (shape.src === '__USER_SQUARE_LOGO__') {
              return { ...shape, src: squareLogoUrl };
            }
          }
          return shape;
        }),
      }))
    );
  }
}, [logoUrl, squareLogoUrl, currentUser]); // ✅ Runs when the user logs in or their assigned logo changes



  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLastPageModal, setShowLastPageModal] = useState(false);
  const [deletePageId, setDeletePageId] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [shapeProperties, setShapeProperties] = useState({
    strokeWidth: 2,
    strokeColor: '#000000',
    fillColor: '#FFFFFF',
    opacity: 1,
    cornerRadius: 10, // Updated from rx and ry
    dashEnabled: false, // Added for dashed strokes
    dash: [10, 5],       // Default dash pattern
    strokeEnabled: true, // Add this line
    fillEnabled: true,   // Add this line
  });
  const [lineProperties, setLineProperties] = useState({
    strokeWidth: 2,
    strokeColor: '#000000',
    dashEnabled: false,
    dash: [10, 5],
  });
  const [pencilProperties, setPencilProperties] = useState({
    strokeWidth: 2,
    strokeColor: '#000000',
    tension: 1,
    dashEnabled: false, // Add this line
    dash: [10, 5],       // Add this line (default dash pattern)
    opacity: 1,
  });
  const [textProperties, setTextProperties] = useState({
    text: '', // Add this line
    fontFamily: 'Arial',
    fontSize: 20,
    fill: '#000000',
    opacity: 1,
    bold: false,
    italic: false,
    underline: false,
    align: 'left',
    width: 200,
    height: 100,
  });
  const [selectedShapeType, setSelectedShapeType] = useState('rect');
  const [drawing, setDrawing] = useState(false);
  const [selectedShapeIds, setSelectedShapeIds] = useState([]);
  const transformerRef = useRef(null);
  const imageInputRef = useRef(null);
  const [offset, setOffset] = useState({
    startX: 0,
    startY: 0,
    initialX: 0,
    initialY: 0,
  });
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const [workspaceBackgroundColor, setWorkspaceBackgroundColor] =
    useState('#f0f0f0');
  const [selectionRect, setSelectionRect] = useState(null);
  const selectionStartPoint = useRef(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const stageRef = useRef(null);
  const initialPositionsRef = useRef({});
  const initialPointerPosRef = useRef({ x: 0, y: 0 });
  const [isEditingText, setIsEditingText] = useState(false);
  const [editingTextPosition, setEditingTextPosition] = useState({
    x: 0,
    y: 0,
  });
  const [editingTextId, setEditingTextId] = useState(null);
  const [editingTextValue, setEditingTextValue] = useState('');
  const undoStack = useRef([]);
  const redoStack = useRef([]);
  const copiedShapesRef = useRef([]);
  const lastExternalImageRef = useRef(null); // ✅ Cache the last pasted external image data
  const [canPaste, setCanPaste] = useState(false);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isQuartersGridVisible, setIsQuartersGridVisible] = useState(false);
  const [isThirdsGridVisible, setIsThirdsGridVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // Initialize as 
  const [isLoading, setIsLoading] = useState(false); // New state to manage loading spinner


  const isMovingRef = useRef(false);

  // Custom Context Menu State
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  // Add and duplicate page functions
  const addBlankPage = () => {
    pushToUndoStack();
    const newPage = {
      id: pages.length,
      shapes: [],
      backgroundColor: '#ffffff',
      orientation: pages[activePage].orientation,
      width: pages[activePage].orientation === 'portrait' ? 794 : 1123,
      height: pages[activePage].orientation === 'portrait' ? 1123 : 794,
    };
    const updatedPages = [...pages, newPage];
    setPages(updatedPages);
    setActivePage(updatedPages.length - 1);
  };

  const duplicateCurrentPage = () => {
    pushToUndoStack();
    const currentPage = pages[activePage];
    const newPage = {
      ...JSON.parse(JSON.stringify(currentPage)),
      id: pages.length,
    };
    const updatedPages = [...pages, newPage];
    setPages(updatedPages);
    setActivePage(updatedPages.length - 1);
  };

  // Define the addText function
  const addText = (x, y) => {
    const newText = {
      id: Date.now(),
      type: 'text',
      x: x,
      y: y,
      rotation: 0,
      scaleX: 1,
      scaleY: 1,
      text: 'Your Text Here',
      align: 'left', // default alignment
      width: 200,    // set a default width
      height: 100,   // set a default height
      fontSize: textProperties.fontSize,
      fontFamily: textProperties.fontFamily,
      fill: textProperties.fill,
      opacity: textProperties.opacity,
      bold: textProperties.bold,
      italic: textProperties.italic,
      underline: textProperties.underline,
      locked: false, // Initialize as unlocked
    };

    const updatedPages = [...pages];
    updatedPages[activePage].shapes.push(newText);
    setPages(updatedPages);
    setSelectedShapeIds([newText.id.toString()]);
    setIsEditingText(true);
    setEditingTextPosition({ x, y });
    setEditingTextId(newText.id.toString());
    setEditingTextValue(newText.text);
    setSelectedTool('select'); // Switch to the selection tool
  };

  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const loadStateFromJSON = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setIsLoading(true);
  
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const loadedState = JSON.parse(event.target.result);
        setPages(loadedState.pages);
        setActivePage(loadedState.activePage);
        setIsLoading(false);
        alert('Your work has been loaded.');
  
        // ✅ After loading, apply the correct logo dynamically
        if (logoUrl) {
          setPages((prevPages) => 
            prevPages.map((page) => ({
              ...page,
              shapes: page.shapes.map((shape) => 
                shape.type === 'image' && shape.src === '__USER_LOGO__'
                  ? { ...shape, src: logoUrl }
                  : shape
              ),
            }))
          );
        }
      } catch (error) {
        setIsLoading(false);
        alert('Failed to load file. Please ensure it is a valid JSON file.');
      }
    };
    reader.readAsText(file);
  };  

  
  const saveStateAsJSON = () => {
    setIsLoading(true);
  
    const stateToSave = {
      fileType: 'EDUGROOVE', // 👈 Marker to identify your file
      version: '1.0',         // 👈 Optional, for future-proofing
      pages,
      activePage,
    };
  
    const prettyJson = JSON.stringify(stateToSave, null, 2);
    const blob = new Blob([prettyJson], { type: 'application/json' });
  
    const exportFileDefaultName = sanitizeFilename(exportFilename, 'groove');
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = exportFileDefaultName;
    link.click();
  
    setIsLoading(false);
  };
  

  const [isExporting, setIsExporting] = useState(false); // New state variable  

  const exportToPDF = async () => {
    setIsLoading(true); // Show the loader when exporting
    const canvasContainer = document.getElementById('canvas-container');
    if (!canvasContainer) {
        setIsLoading(false); // Hide the loader if canvas is not found
        alert('Canvas not found!');
        return;
    }

    // Save current grid visibility, scale, and panOffset states
    const previousGridVisibility = {
        isGridVisible,
        isQuartersGridVisible,
        isThirdsGridVisible,
    };
    const previousScale = scale;
    const previousPanOffset = { ...panOffset };

    try {
        // Prepare for export
        canvasContainer.classList.add('no-border');
        setIsGridVisible(false);
        setIsQuartersGridVisible(false);
        setIsThirdsGridVisible(false);
        setIsExporting(true);
        setScale(1);
        setPanOffset({ x: 0, y: 0 }); // Reset canvas position

        await new Promise((resolve) => setTimeout(resolve, 300)); // Ensure state updates

        // Get the orientation of the first page
        const initialOrientation = pages[0].orientation;
        const jsPDFOrientation = initialOrientation === 'portrait' ? 'p' : 'l';

        const pdf = new jsPDF(jsPDFOrientation, 'pt', 'a4');

        for (let i = 0; i < pages.length; i++) {
            setActivePage(i);
            await new Promise((resolve) => setTimeout(resolve, 500));

            const canvasWidth = canvasContainer.scrollWidth;
            const canvasHeight = canvasContainer.scrollHeight;

            const canvas = await html2canvas(canvasContainer, {
                scale: 2,
                useCORS: true,
                width: canvasWidth,
                height: canvasHeight,
            });

            const imgData = canvas.toDataURL('image/png');

            // Get current page orientation
            const currentOrientation = pages[i].orientation;
            const currentJsPDFOrientation = currentOrientation === 'portrait' ? 'p' : 'l';

            const imgWidth = pdf.internal.pageSize.getWidth();
            const imgHeight = (canvasHeight * imgWidth) / canvasWidth;

            if (i > 0) {
                pdf.addPage('a4', currentJsPDFOrientation);
            }

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        }

        const filename = exportFilename ? `${exportFilename}.pdf` : 'canvas.pdf';
        pdf.save(filename);

        alert('PDF exported successfully.');
    } catch (error) {
        console.error('Error exporting to PDF:', error);
        alert('Failed to export to PDF.');
    } finally {
        // Restore previous states
        setScale(previousScale);
        setPanOffset(previousPanOffset); // Restore the panOffset
        setIsGridVisible(previousGridVisibility.isGridVisible);
        setIsQuartersGridVisible(previousGridVisibility.isQuartersGridVisible);
        setIsThirdsGridVisible(previousGridVisibility.isThirdsGridVisible);
        canvasContainer.classList.remove('no-border');
        setIsExporting(false);
        setIsLoading(false);
    }
};
    

  // Determine if all selected shapes are locked
  const areAllSelectedLocked =
    selectedShapeIds.length > 0 &&
    selectedShapeIds.every((id) => {
      const shape = pages[activePage].shapes.find(
        (s) => s.id.toString() === id
      );
      return shape && shape.locked;
    });

  const lockSelectedShapes = () => {
    if (selectedShapeIds.length === 0) return;

    pushToUndoStack(); // Save state for undo functionality

    const updatedPages = [...pages];
    const shouldLock = !areAllSelectedLocked; // Toggle lock state based on current state

    updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
      if (selectedShapeIds.includes(shape.id.toString())) {
        return {
          ...shape,
          locked: shouldLock, // Toggle lock state
        };
      }
      return shape;
    });

    setPages(updatedPages);
  };

  
  // Keyboard shortcuts handling
  useEffect(() => {
    const handleKeyDown = (e) => {
      const tag = e.target.tagName;
      const isInput =
        tag === 'INPUT' || tag === 'TEXTAREA' || e.target.isContentEditable;
      if (isInput) return;

      if (e.key === 'Shift') {
        setIsShiftPressed(true);
      }

      if (e.ctrlKey && e.key === 'z') {
        e.preventDefault();
        undo();
      }

      if (e.ctrlKey && e.shiftKey && e.key === 'Z') {
        e.preventDefault();
        redo();
      }

      if (e.key === 'Delete' || e.key === 'Backspace') {
        e.preventDefault();
        deleteSelectedShapes();
      }

      if (e.ctrlKey && e.key === 'c') {
        e.preventDefault();
        copySelectedShapes();
      }

      if (e.ctrlKey && e.key === 'v') {
        e.preventDefault();
        handlePaste(e); // ✅ Use your full handler that checks both clipboard and internal copy
      }      

      if (e.ctrlKey && e.key === 's') {
        e.preventDefault();
        saveCanvas();
      }

      if (e.key === '=') {
        e.preventDefault();
        handleZoomIn();
      }

      if (e.key === '+') {
        e.preventDefault();
        handleZoomIn();
      }

      if (e.key === '-') {
        e.preventDefault();
        handleZoomOut();
      }

      if (!e.ctrlKey && !e.metaKey && !e.altKey) {
        switch (e.key.toLowerCase()) {
          case 'g':
            handleToolClick('hand');
            break;
          case 'v':
            handleToolClick('select');
            break;
          case 's':
            handleToolClick('shape');
            break;
          case 'l':
            handleToolClick('line');
            break;
            case 'p':
              handleToolClick('pencil');
              break;  
          case 't':
            handleToolClick('text');
            break;
          case 'w':
            handleToolClick('workspaceInfo');
            break;
          case 'i':
            setShowImageLibraryModal(true);
            break;
          case 'z':
            handleToolClick('zoom');
            break;
          case 'a':
            addPage();
            break;          
          default:
            break;
        }
      }

      if (!e.ctrlKey && !e.metaKey && !e.altKey) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          bringForward();
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          sendBack();
        }
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Shift') {
        setIsShiftPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    selectedShapeIds,
    canPaste,
    scale,
    panOffset,
    pages,
    activePage,
    selectedTool,
  ]);

  // Undo and redo functions
  const pushToUndoStack = () => {
    console.log('Pushing to undo stack'); // Log when pushing to undo stack
  
    const pagesCopy = JSON.parse(JSON.stringify(pages));
    undoStack.current.push(pagesCopy);
  
    if (undoStack.current.length > 50) {
      undoStack.current.shift();
    }
  
    redoStack.current = []; // Clear redo stack since we have a new action
    console.log(`Undo Stack Size: ${undoStack.current.length}`);
    console.log(`Redo Stack Size: ${redoStack.current.length}`);
  };
  
  

  const undo = () => {
    if (undoStack.current.length === 0) return;
  
    // Pop the previous state from the stack and apply it
    const previousPages = undoStack.current.pop();
    const currentPagesCopy = JSON.parse(JSON.stringify(pages));
    redoStack.current.push(currentPagesCopy);
  
    // Apply the previous state
    setPages(previousPages);
    setSelectedShapeIds([]); // Clear selection after undo
  };
  

  const redo = () => {
    if (redoStack.current.length === 0) return;
    const nextPages = redoStack.current.pop();
    const currentPagesCopy = JSON.parse(JSON.stringify(pages));
    undoStack.current.push(currentPagesCopy);
    setPages(nextPages);
    setSelectedShapeIds([]);
  };

  // Action handlers
  const deleteSelectedShapes = () => {
    if (selectedShapeIds.length === 0) return;
  
    // Identify which selected shapes are locked and which are not
    const lockedShapes = pages[activePage].shapes.filter(
      (shape) => selectedShapeIds.includes(shape.id.toString()) && shape.locked
    );
    const unlockedShapes = pages[activePage].shapes.filter(
      (shape) => selectedShapeIds.includes(shape.id.toString()) && !shape.locked
    );
  
    // If all selected shapes are locked, notify the user and prevent deletion
    if (lockedShapes.length === selectedShapeIds.length) {
      alert('Cannot delete selected shapes because they are locked.');
      return;
    }
  
    // If some selected shapes are locked, notify the user that only unlocked shapes will be deleted
    if (lockedShapes.length > 0) {
      alert('Some selected shapes are locked and could not be deleted.');
    }
  
    // Proceed to delete only the unlocked shapes
    // Proceed to delete only the unlocked shapes
      pushToUndoStack(); // ✅ Add this to register deletion in undo history

      const updatedPages = [...pages];
      updatedPages[activePage].shapes = updatedPages[activePage].shapes.filter(
        (shape) => !(selectedShapeIds.includes(shape.id.toString()) && !shape.locked)
      );
      setPages(updatedPages);
      setSelectedShapeIds([]);
  };

 
const bringForward = () => {
  if (selectedShapeIds.length === 0) return;

  // Identify which selected shapes are locked and which are not
  const lockedShapes = pages[activePage].shapes.filter(
    (shape) => selectedShapeIds.includes(shape.id.toString()) && shape.locked
  );
  const unlockedShapes = pages[activePage].shapes.filter(
    (shape) => selectedShapeIds.includes(shape.id.toString()) && !shape.locked
  );

  // If all selected shapes are locked, notify the user and prevent moving
  if (lockedShapes.length === selectedShapeIds.length) {
    alert('Cannot move selected shapes because they are locked.');
    return;
  }

  // If some selected shapes are locked, notify the user that only unlocked shapes will be moved
  if (lockedShapes.length > 0) {
    alert('Some selected shapes are locked and could not be moved forward.');
  }

  pushToUndoStack();

  // Proceed to move only the unlocked shapes forward
  const updatedPages = [...pages];
  const shapes = updatedPages[activePage].shapes;

  // Sort unlocked shapes from top to bottom to prevent overlapping issues
  const sortedUnlockedIds = [...unlockedShapes]
    .sort((a, b) => shapes.findIndex((s) => s.id === b.id) - shapes.findIndex((s) => s.id === a.id))
    .map((shape) => shape.id.toString());

  sortedUnlockedIds.forEach((id) => {
    const index = shapes.findIndex((shape) => shape.id.toString() === id);
    if (index < shapes.length - 1) {
      [shapes[index], shapes[index + 1]] = [shapes[index + 1], shapes[index]];
    }
  });

  setPages(updatedPages);
};


const sendBack = () => {
  if (selectedShapeIds.length === 0) return;

  // Identify which selected shapes are locked and which are not
  const lockedShapes = pages[activePage].shapes.filter(
    (shape) => selectedShapeIds.includes(shape.id.toString()) && shape.locked
  );
  const unlockedShapes = pages[activePage].shapes.filter(
    (shape) => selectedShapeIds.includes(shape.id.toString()) && !shape.locked
  );

  // If all selected shapes are locked, notify the user and prevent moving
  if (lockedShapes.length === selectedShapeIds.length) {
    alert('Cannot move selected shapes because they are locked.');
    return;
  }

  // If some selected shapes are locked, notify the user that only unlocked shapes will be moved
  if (lockedShapes.length > 0) {
    alert('Some selected shapes are locked and could not be moved backward.');
  }

  pushToUndoStack();

  // Proceed to move only the unlocked shapes backward
  const updatedPages = [...pages];
  const shapes = updatedPages[activePage].shapes;

  // Sort unlocked shapes from bottom to top to prevent overlapping issues
  const sortedUnlockedIds = [...unlockedShapes]
    .sort((a, b) => shapes.findIndex((s) => s.id === a.id) - shapes.findIndex((s) => s.id === b.id))
    .map((shape) => shape.id.toString());

  sortedUnlockedIds.forEach((id) => {
    const index = shapes.findIndex((shape) => shape.id.toString() === id);
    if (index > 0) {
      [shapes[index], shapes[index - 1]] = [shapes[index - 1], shapes[index]];
    }
  });

  setPages(updatedPages);
};

  // Tool click handlers
  const handleToolClick = (tool) => {
    setSelectedTool(tool);
    setSelectedShapeIds([]);
    setSelectionRect(null);
    setIsEditingText(false);
  };

  const handleMouseEnter = (tool) => {
    setHoveredTool(tool);
  };

  const handleMouseLeave = () => {
    setHoveredTool(null);
  };

  const togglePropertiesPanel = () => {
    setIsPropertiesCollapsed(!isPropertiesCollapsed);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  // Canvas Pan and Zoom Handlers
  const handleCanvasMouseDownDrag = (e) => {
    if (selectedTool === 'hand') {
      setDragging(true);
      setOffset({
        startX: e.clientX,
        startY: e.clientY,
        initialX: panOffset.x,
        initialY: panOffset.y,
      });
    }
  };

  const handleCanvasMouseMoveDrag = (e) => {
    if (dragging) {
      const dx = e.clientX - offset.startX;
      const dy = e.clientY - offset.startY;
      setPanOffset({
        x: offset.initialX + dx,
        y: offset.initialY + dy,
      });
    }
  };

  const handleCanvasMouseUpDrag = () => {
    if (dragging) {
      setDragging(false);
    }
  };

  // Stage Mouse Handlers
  const handleStageMouseDown = (e) => {
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
  
    if (selectedTool === 'pencil') {
      pushToUndoStack();

      console.log("Drawing new pencil line with tension:", pencilProperties.tension);

      const newPencilLine = {
        id: Date.now(),
        type: 'pencil',
        points: [pos.x, pos.y],
        x: 0,
        y: 0,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        strokeWidth: pencilProperties.strokeWidth,
        strokeColor: pencilProperties.strokeColor,
        opacity: pencilProperties.opacity, // ✅ Add this line
        tension: pencilProperties.tension,
        lineCap: 'round',
        lineJoin: 'round',
        dashEnabled: pencilProperties.dashEnabled,
        dash: pencilProperties.dashEnabled ? pencilProperties.dash : [],
        locked: false,
      };
  
      const updatedPages = [...pages];
      updatedPages[activePage].shapes.push(newPencilLine);
      setPages(updatedPages);
      setDrawing(true);
    } else if (selectedTool === 'line') {
      pushToUndoStack();
      const newLine = {
        id: Date.now(),
        type: 'line',
        points: [pos.x, pos.y, pos.x, pos.y],
        x: 0,
        y: 0,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        strokeWidth: lineProperties.strokeWidth,
        strokeColor: lineProperties.strokeColor,
        dashEnabled: lineProperties.dashEnabled,
        dash: lineProperties.dashEnabled ? lineProperties.dash : [],
        locked: false,
      };
  
      const updatedPages = [...pages];
      updatedPages[activePage].shapes.push(newLine);
      setPages(updatedPages);
      setDrawing(true);
    } else if (selectedTool === 'shape') {
      pushToUndoStack();
      let newShape = {
        id: Date.now(),
        type: selectedShapeType,
        initialX: pos.x, // Store initial X position
        initialY: pos.y, // Store initial Y position
        x: pos.x,
        y: pos.y,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
        strokeWidth: shapeProperties.strokeWidth,
        strokeColor: shapeProperties.strokeColor,
        fillColor: shapeProperties.fillColor,
        opacity: shapeProperties.opacity,
        dashEnabled: shapeProperties.dashEnabled,
        dash: shapeProperties.dashEnabled ? shapeProperties.dash : [],
        strokeEnabled:
          selectedTool === 'shape'
            ? shapeProperties.strokeEnabled
            : true,
        fillEnabled:
          selectedTool === 'shape'
            ? shapeProperties.fillEnabled
            : true,
        locked: false,
      };
  
      // Initialize shape-specific properties
      switch (selectedShapeType) {
        case 'rect':
        case 'roundedRect':
          newShape = {
            ...newShape,
            width: 0,
            height: 0,
            cornerRadius: shapeProperties.cornerRadius || 10,
          };
          break;
        case 'circle':
          newShape = {
            ...newShape,
            radius: 0,
          };
          break;
        case 'triangle':
        case 'pentagon':
        case 'hexagon':
        case 'octagon':
          newShape = {
            ...newShape,
            radius: 0,
          };
          break;
        case 'star':
          newShape = {
            ...newShape,
            numPoints: 5, // Default number of points
            innerRadius: 0,
            outerRadius: 0,
          };
          break;
        default:
          break;
      }
  
      const updatedPages = [...pages];
      updatedPages[activePage].shapes.push(newShape);
      setPages(updatedPages);
      setDrawing(true);
    } else if (selectedTool === 'select') {
      if (e.target === stage) {
        selectionStartPoint.current = pos;
        setSelectionRect({
          x: pos.x,
          y: pos.y,
          width: 0,
          height: 0,
        });
        setIsSelecting(true);
        setSelectedShapeIds([]);
      } else {
        const clickedOnTransformer =
          e.target.getParent().className === 'Transformer';
        if (!clickedOnTransformer) {
          handleShapeClick(e, e.target.id());
        }
      }
    } else if (selectedTool === 'text') {
      pushToUndoStack();
      addText(pos.x, pos.y);
      setSelectedTool(null);
    }
  };

  const handleStageMouseMove = (e) => {
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const isShift = isShiftPressed;
  
    if (drawing) {
      const updatedPages = [...pages];
      const lastShapeIndex = updatedPages[activePage].shapes.length - 1;
      const shape = updatedPages[activePage].shapes[lastShapeIndex];
  
      if (selectedTool === 'shape') {
        const startX = shape.initialX;
        const startY = shape.initialY;
        const currentX = pos.x;
        const currentY = pos.y;
  
        let x = Math.min(startX, currentX);
        let y = Math.min(startY, currentY);
        let width = Math.abs(currentX - startX);
        let height = Math.abs(currentY - startY);
  
        // Enforce minimum size if needed
        const minSize = 1;
        width = Math.max(width, minSize);
        height = Math.max(height, minSize);
  
        if (isShift) {
          const size = Math.max(width, height);
          width = size;
          height = size;
          x = startX < currentX ? startX : startX - size;
          y = startY < currentY ? startY : startY - size;
        }
  
        const newShapeData = {
          x,
          y,
        };
  
        if (shape.type === 'rect' || shape.type === 'roundedRect') {
          newShapeData.width = width;
          newShapeData.height = height;
        } else if (shape.type === 'circle') {
          const dx = currentX - startX;
          const dy = currentY - startY;
          let radius = Math.hypot(dx, dy);
          if (isShift) {
            radius = Math.max(Math.abs(dx), Math.abs(dy));
          }
          newShapeData.radius = radius;
          newShapeData.x = startX;
          newShapeData.y = startY;
        } else if (
          ['triangle', 'pentagon', 'hexagon', 'octagon'].includes(shape.type)
        ) {
          const dx = currentX - startX;
          const dy = currentY - startY;
          let radius = Math.hypot(dx, dy);
          if (isShift) {
            radius = Math.max(Math.abs(dx), Math.abs(dy));
          }
          newShapeData.radius = radius;
          newShapeData.x = startX;
          newShapeData.y = startY;
        } else if (shape.type === 'star') {
          const dx = currentX - startX;
          const dy = currentY - startY;
          let outerRadius = Math.hypot(dx, dy);
          if (isShift) {
            outerRadius = Math.max(Math.abs(dx), Math.abs(dy));
          }
          newShapeData.outerRadius = outerRadius;
          newShapeData.innerRadius = outerRadius / 2;
          newShapeData.x = startX;
          newShapeData.y = startY;
        }
  
        updatedPages[activePage].shapes[lastShapeIndex] = {
          ...shape,
          ...newShapeData,
        };
      } else if (selectedTool === 'line') {
        const x1 = shape.points[0];
        const y1 = shape.points[1];
        let x2 = pos.x;
        let y2 = pos.y;
  
        if (isShift) {
          const dx = x2 - x1;
          const dy = y2 - y1;
          const angle = Math.atan2(dy, dx);
          const snappedAngle = Math.round(angle / (Math.PI / 4)) * (Math.PI / 4);
          const distance = Math.hypot(dx, dy);
          x2 = x1 + distance * Math.cos(snappedAngle);
          y2 = y1 + distance * Math.sin(snappedAngle);
        }
  
        const newPoints = [x1, y1, x2, y2];
        updatedPages[activePage].shapes[lastShapeIndex] = {
          ...shape,
          points: newPoints,
        };
      } else if (selectedTool === 'pencil') {
        const newPoints = [...shape.points, pos.x, pos.y];
        updatedPages[activePage].shapes[lastShapeIndex] = {
          ...shape,
          points: newPoints,
        };
      }
  
      setPages(updatedPages);
    } else if (isSelecting && selectionRect) {
      const sx = selectionStartPoint.current.x;
      const sy = selectionStartPoint.current.y;
      const dx = pos.x - sx;
      const dy = pos.y - sy;
  
      setSelectionRect({
        x: sx,
        y: sy,
        width: dx,
        height: dy,
      });
    }
  };
  
  
  
  const handleStageMouseUp = () => {
    if (drawing) {
      setDrawing(false);
    }

    if (isSelecting) {
      const selBox = {
        x: Math.min(selectionRect.x, selectionRect.x + selectionRect.width),
        y: Math.min(selectionRect.y, selectionRect.y + selectionRect.height),
        width: Math.abs(selectionRect.width),
        height: Math.abs(selectionRect.height),
      };

      const stage = stageRef.current;
      const shapes = pages[activePage].shapes;
      const selectedIds = [];

      shapes.forEach((shape) => {
        const shapeNode = stage.findOne(`#${shape.id}`);
        if (shapeNode) {
          const shapeBox = shapeNode.getClientRect();
          // Intersection condition
          if (
            shapeBox.x < selBox.x + selBox.width &&
            shapeBox.x + shapeBox.width > selBox.x &&
            shapeBox.y < selBox.y + selBox.height &&
            shapeBox.y + shapeBox.height > selBox.y
          ) {
            selectedIds.push(shape.id.toString());
          }
        }
      });

      setSelectedShapeIds(selectedIds);
      setSelectionRect(null);
      setIsSelecting(false);
    }
  };

  // Update Shape Properties Handlers  

  const updateShapePropertiesHandler = (e, property) => {
    pushToUndoStack(); // Save state before changes
  
    let value = e.target.value;
  
    if (property === 'dashEnabled' || property === 'strokeEnabled' || property === 'fillEnabled') {
      value = e.target.checked;
  
      if (property === 'dashEnabled') {
        const updatedPages = [...pages];
        updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
          if (
            [
              'rect',
              'roundedRect',
              'circle',
              'polygon',
              'star',
              'triangle',
              'pentagon',
              'hexagon',
              'octagon',
              'image',
            ].includes(shape.type) &&
            selectedShapeIds.includes(shape.id.toString())
          ) {
            return {
              ...shape,
              [property]: value,
              dash: value ? (shape.dash && shape.dash.length > 0 ? shape.dash : [10, 5]) : [],
            };
          }
          return shape;
        });
  
        setPages(updatedPages);
        return;
      }
  
      if (property === 'strokeEnabled' || property === 'fillEnabled') {
        const updatedPages = [...pages];
        updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
          if (
            [
              'rect',
              'roundedRect',
              'circle',
              'polygon',
              'star',
              'triangle',
              'pentagon',
              'hexagon',
              'octagon',
              'image',
            ].includes(shape.type) &&
            selectedShapeIds.includes(shape.id.toString())
          ) {
            return {
              ...shape,
              [property]: value,
            };
          }
          return shape;
        });
  
        setPages(updatedPages);
        return;
      }
    }
  
    if (property === 'dash') {
      // Parse the dash pattern into an array of numbers
      value = e.target.value.split(',').map((val) => {
        const parsed = parseInt(val.trim(), 10);
        return isNaN(parsed) ? 0 : parsed; // Handle any invalid input gracefully
      });
  
      // Update the shapes' dash property
      const updatedPages = [...pages];
      updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
        if (
          [
            'rect',
            'roundedRect',
            'circle',
            'polygon',
            'star',
            'triangle',
            'pentagon',
            'hexagon',
            'octagon',
            'image',
          ].includes(shape.type) &&
          selectedShapeIds.includes(shape.id.toString())
        ) {
          return {
            ...shape,
            dash: value,
          };
        }
        return shape;
      });
  
      setPages(updatedPages);
      return;
    }
  
    // Handle other properties
    if (['strokeWidth', 'opacity', 'cornerRadius'].includes(property)) {
      value = parseFloat(value);
    } else if (property === 'fillColor' || property === 'strokeColor') {
      value = e.target.value;
    }
  
    // Update the shapes' property directly
    const updatedPages = [...pages];
    updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
      if (
        [
          'rect',
          'roundedRect',
          'circle',
          'polygon',
          'star',
          'triangle',
          'pentagon',
          'hexagon',
          'octagon',
          'image',
        ].includes(shape.type) &&
        selectedShapeIds.includes(shape.id.toString())
      ) {
        return {
          ...shape,
          [property]: value,
        };
      }
      return shape;
    });
  
    setPages(updatedPages);
  };
  
  
  
  
  const updateLinePropertiesHandler = (e, property) => {
    pushToUndoStack(); // Save state before changes
  
    let value = e.target.value;
  
    if (property === 'dashEnabled') {
      value = e.target.checked;
  
      // Update lineProperties state
      setLineProperties((prev) => ({
        ...prev,
        [property]: value,
        dash: value ? (prev.dash && prev.dash.length > 0 ? prev.dash : [10, 5]) : [],
      }));
  
      // Update shapes in the pages
      const updatedPages = [...pages];
      updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
        if (
          shape.type === 'line' &&
          selectedShapeIds.includes(shape.id.toString())
        ) {
          const updatedShape = {
            ...shape,
            dashEnabled: value,
            dash: value ? (shape.dash && shape.dash.length > 0 ? shape.dash : [10, 5]) : [],
          };
          return updatedShape;
        }
        return shape;
      });
  
      setPages(updatedPages);
      return;
    }
  
    if (property === 'dash') {
      // Parse the dash pattern into an array of numbers
      value = e.target.value.split(',').map((val) => {
        const parsed = parseInt(val.trim(), 10);
        return isNaN(parsed) ? 0 : parsed; // Handle any invalid input gracefully
      });
  
      setLineProperties((prev) => ({
        ...prev,
        [property]: value,
      }));
  
      // Update the shapes' dash property
      const updatedPages = [...pages];
      updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
        if (
          shape.type === 'line' &&
          selectedShapeIds.includes(shape.id.toString())
        ) {
          return {
            ...shape,
            dash: value,
          };
        }
        return shape;
      });
  
      setPages(updatedPages);
      return;
    }
  
    // Handle other properties
    if (['strokeWidth'].includes(property)) {
      value = parseFloat(value);
    } else if (property === 'strokeColor') {
      value = e.target.value;
    }
  
    setLineProperties((prev) => ({
      ...prev,
      [property]: value,
    }));
  
    const updatedPages = [...pages];
    updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
      if (
        shape.type === 'line' &&
        selectedShapeIds.includes(shape.id.toString())
      ) {
        return {
          ...shape,
          [property]: value,
        };
      }
      return shape;
    });
  
    setPages(updatedPages);
  };
  

  // In MainApp.js, replace your updatePencilPropertiesHandler function with this version:
const updatePencilPropertiesHandler = (e, property) => {
  pushToUndoStack(); // Save state before changes
  let value;

  // Handle dashEnabled property separately
  if (property === 'dashEnabled') {
    value = e.target.checked;
    setPencilProperties((prev) => ({
      ...prev,
      dashEnabled: value,
      dash: value ? (prev.dash && prev.dash.length > 0 ? prev.dash : [10, 5]) : [],
    }));

    // Update all pencil shapes that are selected
    const updatedPages = [...pages];
    updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
      if (shape.type === 'pencil' && selectedShapeIds.includes(shape.id.toString())) {
        return {
          ...shape,
          dashEnabled: value,
          dash: value ? (shape.dash && shape.dash.length > 0 ? shape.dash : [10, 5]) : [],
        };
      }
      return shape;
    });
    setPages(updatedPages);

    // *** NEW CODE: Also update the active pencil stroke if drawing ***
    if (selectedTool === 'pencil' && drawing) {
      const activePageShapes = [...pages[activePage].shapes];
      const lastIndex = activePageShapes.length - 1;
      const activePencil = activePageShapes[lastIndex];
      if (activePencil && activePencil.type === 'pencil') {
        activePencil.dashEnabled = value;
        activePencil.dash = value ? (activePencil.dash && activePencil.dash.length > 0 ? activePencil.dash : [10, 5]) : [];
        const newPages = [...pages];
        newPages[activePage].shapes[lastIndex] = activePencil;
        setPages(newPages);
      }
    }
    return;
  }

  // Handle dash (pattern) property separately
  if (property === 'dash') {
    value = e.target.value.split(',').map((val) => {
      const num = parseInt(val.trim(), 10);
      return isNaN(num) ? 0 : num;
    });
    setPencilProperties((prev) => ({
      ...prev,
      dash: value,
    }));
    const updatedPages = [...pages];
    updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
      if (shape.type === 'pencil' && selectedShapeIds.includes(shape.id.toString())) {
        return { ...shape, dash: value };
      }
      return shape;
    });
    setPages(updatedPages);

    // *** NEW CODE: Update the active pencil stroke if drawing ***
    if (selectedTool === 'pencil' && drawing) {
      const activePageShapes = [...pages[activePage].shapes];
      const lastIndex = activePageShapes.length - 1;
      const activePencil = activePageShapes[lastIndex];
      if (activePencil && activePencil.type === 'pencil') {
        activePencil.dash = value;
        const newPages = [...pages];
        newPages[activePage].shapes[lastIndex] = activePencil;
        setPages(newPages);
      }
    }
    return;
  }

  // For other properties like strokeWidth, tension, opacity, or strokeColor
  if (['strokeWidth', 'tension', 'opacity'].includes(property)) {
    value = parseFloat(e.target.value);
    if (property === 'strokeWidth') {
      value = Math.max(0, value); // Prevent negative stroke widths
    }
  } else if (property === 'strokeColor') {
    value = e.target.value;
  }

  // Update the pencilProperties state
  setPencilProperties((prev) => ({
    ...prev,
    [property]: value,
  }));

  // Update all pencil shapes that are selected
  const updatedPages = [...pages];
  updatedPages[activePage].shapes = updatedPages[activePage].shapes.map((shape) => {
    if (shape.type === 'pencil' && selectedShapeIds.includes(shape.id.toString())) {
      return { ...shape, [property]: value };
    }
    return shape;
  });
  setPages(updatedPages);

  // *** NEW CODE: Also update the active pencil stroke if drawing ***
  if (selectedTool === 'pencil' && drawing) {
    const activePageShapes = [...pages[activePage].shapes];
    const lastIndex = activePageShapes.length - 1;
    const activePencil = activePageShapes[lastIndex];
    if (activePencil && activePencil.type === 'pencil') {
      activePencil[property] = value;
      const newPages = [...pages];
      newPages[activePage].shapes[lastIndex] = activePencil;
      setPages(newPages);
    }
  }
};

  
const updateTextPropertiesHandler = (e, property) => {
  pushToUndoStack();
  const value =
    property === 'bold' || property === 'italic' || property === 'underline'
      ? e.target.checked
      : e.target.value;

  setTextProperties((prev) => ({
    ...prev,
    [property]: property === 'fontSize' ? parseInt(value) : value,
  }));

  const updatedPages = [...pages];
  updatedPages[activePage].shapes = updatedPages[activePage].shapes.map(
    (shape) => {
      if (
        shape.type === 'text' &&
        selectedShapeIds.includes(shape.id.toString())
      ) {
        return {
          ...shape,
          [property]:
            property === 'fontSize'
              ? parseInt(value)
              : property === 'bold' ||
                property === 'italic' ||
                property === 'underline'
              ? e.target.checked
              : value,
        };
      }
      return shape;
    }
  );
  setPages(updatedPages);
};


  // Handle Image Upload
  const triggerImageUpload = () => {
    imageInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      pushToUndoStack();
      const reader = new FileReader();
      reader.onload = (event) => {
        const newImage = {
          id: Date.now(),
          type: 'image',
          src: event.target.result,
          x: 100,
          y: 100,
          width: 100,
          height: 100,
          rotation: 0,
          scaleX: 1,
          scaleY: 1,
          locked: false,
        };
        const updatedPages = [...pages];
        updatedPages[activePage].shapes.push(newImage);
        setPages(updatedPages);
        setSelectedTool('select'); // Switch to the selection tool
  
        // Reset the input's value to allow uploading the same file again
        e.target.value = null;
      };
      reader.readAsDataURL(file);
    }
  };

  // Change Canvas Orientation and Mood
  const changeCanvasOrientation = (orientation) => {
    pushToUndoStack();
    const updatedPages = pages.map((page) => {
      if (orientation === 'portrait') {
        return {
          ...page,
          orientation: 'portrait',
          width: 794,
          height: 1123,
        };
      } else {
        return {
          ...page,
          orientation: 'landscape',
          width: 1123,
          height: 794,
        };
      }
    });
    setPages(updatedPages);
  };

  // Inside MainApp component

  const changeMood = (mood) => {
    if (mood && backgroundImages[mood]) {
      setWorkspaceBackgroundImage(backgroundImages[mood]);
      setWorkspaceBackgroundColor(null); // Remove background color when image is set
    } else {
      setWorkspaceBackgroundImage(null);
      setWorkspaceBackgroundColor('#f0f0f0'); // Default background color
    }
  };

  

  // Zoom Handlers
  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prevScale) =>
      prevScale - 0.1 > 0.1 ? prevScale - 0.1 : 0.1
    );
  };

  // Page Management
  const addPage = () => {
    pushToUndoStack();
    const newPage = {
      id: pages.length,
      shapes: [],
      backgroundColor: '#ffffff',
      orientation: pages[activePage].orientation,
      width: pages[activePage].orientation === 'portrait' ? 794 : 1123,
      height: pages[activePage].orientation === 'portrait' ? 1123 : 794,
    };
    const updatedPages = [...pages, newPage];
    setPages(updatedPages);
    setActivePage(updatedPages.length - 1);
  };

  const deletePage = () => {
    pushToUndoStack();
    const updatedPages = pages.filter((page) => page.id !== deletePageId);
    const renumberedPages = updatedPages.map((page, index) => ({
      ...page,
      id: index,
    }));
    setPages(renumberedPages);
    setActivePage(0);
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (pageId) => {
    if (pages.length > 1) {
      setDeletePageId(pageId);
      setShowDeleteModal(true);
    } else {
      setShowLastPageModal(true);
    }
  };

  const handlePageClick = (pageIndex) => {
    setActivePage(pageIndex);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
    setShowLastPageModal(false);
  };

    // Copy and Paste Handlers
    const copySelectedShapes = () => {
      if (selectedShapeIds.length === 0) return;
    
      const lockedShapes = pages[activePage].shapes.filter(
        (shape) => selectedShapeIds.includes(shape.id.toString()) && shape.locked
      );
      const unlockedShapes = pages[activePage].shapes.filter(
        (shape) => selectedShapeIds.includes(shape.id.toString()) && !shape.locked
      );
    
      if (lockedShapes.length === selectedShapeIds.length) {
        alert('Cannot copy selected shapes because they are locked.');
        return;
      }
    
      if (lockedShapes.length > 0) {
        alert('Some selected shapes are locked and could not be copied.');
      }
    
      // ✅ Clear internal clipboard and mark it
      copiedShapesRef.current = unlockedShapes.map((shape) =>
        JSON.parse(JSON.stringify(shape))
      );
      setCanPaste(true);
      setLastCopySource('internal');
    };
    
    

  const pasteCopiedShapes = () => {
    if (copiedShapesRef.current.length === 0) return;
    pushToUndoStack();
    const pastedShapes = copiedShapesRef.current.map((shape) => {
      const newShape = JSON.parse(JSON.stringify(shape));
      newShape.id = Date.now() + Math.random();
      newShape.x = (newShape.x || 0) + 10;
      newShape.y = (newShape.y || 0) + 10;
      return newShape;
    });
    const updatedPages = [...pages];
    updatedPages[activePage].shapes = [
      ...updatedPages[activePage].shapes,
      ...pastedShapes,
    ];
    setPages(updatedPages);
    setSelectedShapeIds(pastedShapes.map((shape) => shape.id.toString()));
  };

  const handlePaste = async (e) => {
    const isInput =
      e?.target?.tagName === 'INPUT' ||
      e?.target?.tagName === 'TEXTAREA' ||
      e?.target?.isContentEditable;
  
    if (isInput) return;
    if (e?.preventDefault) e.preventDefault();
  
    try {
      const clipboardItems = await navigator.clipboard.read();
  
      for (const item of clipboardItems) {
        if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
          const type = item.types.includes('image/png') ? 'image/png' : 'image/jpeg';
          const blob = await item.getType(type);
  
          const reader = new FileReader();
  
          reader.onload = (event) => {
            const imgSrc = event.target.result;
  
            // ✅ Skip only if it's *exactly* the same image, and internal is available
            if (lastExternalImageRef.current === imgSrc && copiedShapesRef.current.length > 0) {
              console.log('External image already used, falling back to internal clipboard.');
              pasteCopiedShapes();
              return;
            }
  
            pushToUndoStack();
  
            const newImage = {
              id: Date.now(),
              type: 'image',
              src: imgSrc,
              x: 100,
              y: 100,
              width: 200,
              height: 200,
              rotation: 0,
              scaleX: 1,
              scaleY: 1,
              locked: false,
            };
  
            const updatedPages = [...pages];
            updatedPages[activePage].shapes.push(newImage);
            setPages(updatedPages);
            setSelectedShapeIds([newImage.id.toString()]);
            setSelectedTool('select');
  
            // ✅ Clear internal clipboard and mark external source
            copiedShapesRef.current = [];
            setCanPaste(false);
            setLastCopySource('external');
            lastExternalImageRef.current = imgSrc;
          };
  
          reader.readAsDataURL(blob);
          return; // ✅ Success with external image
        }
      }
  
      // ❌ No external image — fallback to internal clipboard
      if (copiedShapesRef.current.length > 0) {
        pasteCopiedShapes();
        return;
      }
  
      alert('Nothing to paste.');
    } catch (err) {
      console.warn('Clipboard read failed:', err);
  
      if (copiedShapesRef.current.length > 0) {
        pasteCopiedShapes();
      } else {
        alert('Nothing to paste.');
      }
    }
  };
  
  

  // Text Editing Handlers
  const handleTextDoubleClick = (shapeId) => {
    setSelectedTool('text');
    setSelectedShapeIds([shapeId]);

    const textShape = pages[activePage].shapes.find(
      (shape) => shape.id.toString() === shapeId
    );
    if (textShape) {
      setEditingTextPosition({ x: textShape.x, y: textShape.y });
      setEditingTextId(shapeId);
      setEditingTextValue(textShape.text);
      setIsEditingText(true);
    }
  };

  const updateTextContentHandler = (newText) => {
    pushToUndoStack();
    setEditingTextValue(newText);
    const updatedPages = [...pages];
    updatedPages[activePage].shapes = updatedPages[activePage].shapes.map(
      (shape) => {
        if (
          shape.type === 'text' &&
          selectedShapeIds.includes(shape.id.toString())
        ) {
          return {
            ...shape,
            text: newText,
          };
        }
        return shape;
      }
    );
    setPages(updatedPages);
  };

  // Shape Click and Drag Handlers
  const handleShapeClick = (e, shapeId) => {
    const shape = pages[activePage].shapes.find(s => s.id.toString() === shapeId);
  
    if (selectedTool === 'select') {
      e.cancelBubble = true;
  
      // If not in edit mode and shape is locked, ignore the click
      if (shape && shape.locked && !isEditMode) {
        return;
      }
  
      if (shape && shape.locked && isEditMode) {
        setSelectedShapeIds([shapeId]);
        return;
      }
  
      // ✅ ALT + click  ➜  duplicate the whole current selection & drag the clone of the shape you clicked
        if (e.evt.altKey && shape && !shape.locked) {
          pushToUndoStack();

          // If the clicked shape is part of a selection use that list, otherwise just clone the one shape
          const sourceIds = selectedShapeIds.includes(shapeId)
            ? selectedShapeIds
            : [shapeId];

          // Build the clones
          const clones = sourceIds.map((id) => {
            const src = pages[activePage].shapes.find((s) => s.id.toString() === id);
            const clone = JSON.parse(JSON.stringify(src));
            clone.id = Date.now() + Math.random();
            return clone;
          });

          // Add them to the page
          const updatedPages = [...pages];
          updatedPages[activePage].shapes.push(...clones);
          setPages(updatedPages);

          // Select the new clones
          const newIds = clones.map((c) => c.id.toString());
          setSelectedShapeIds(newIds);

          // Stop the drag that began on the original node
          e.target.stopDrag();

          // Find the clone that corresponds to the shape the user actually clicked
          const clickedCloneId = clones[sourceIds.indexOf(shapeId)].id.toString();

          // Hand the pointer to that clone so the whole group moves together
          requestAnimationFrame(() => {
            const stage   = e.target.getStage();
            const newNode = stage.findOne(`#${clickedCloneId}`);
            if (newNode) {
              newNode.startDrag();
              handleShapeDragStart(clickedCloneId);   // keep your existing bookkeeping
            }
          });

          return;
        }

      

      // ✅ SHIFT + Click to multi-select
      if (e.evt.shiftKey) {
        const index = selectedShapeIds.indexOf(shapeId);
        if (index >= 0) {
          const ids = selectedShapeIds.slice();
          ids.splice(index, 1);
          setSelectedShapeIds(ids);
        } else {
          setSelectedShapeIds([...selectedShapeIds, shapeId]);
        }
      } else {
        if (!selectedShapeIds.includes(shapeId)) {
          setSelectedShapeIds([shapeId]);
        }
      }
    }
  };
  
  
  const handleShapeDragStart = (shapeId) => {
    if (selectedShapeIds.includes(shapeId)) {
      if (isMovingRef.current) return;
    
      isMovingRef.current = true;
    
      // ✅ Push only once for group move
      pushToUndoStack();
    
      // Store initial positions
      const initialPositions = {};
      pages[activePage].shapes.forEach((shape) => {
        if (selectedShapeIds.includes(shape.id.toString())) {
          initialPositions[shape.id.toString()] = { x: shape.x, y: shape.y };
        }
      });
      initialPositionsRef.current = initialPositions;
    
      // Store initial pointer position
      const stage = stageRef.current;
      const pointerPos = stage.getPointerPosition();
      initialPointerPosRef.current = { x: pointerPos.x, y: pointerPos.y };
    }
    
  };

  const handleShapeDragEnd = ({ target }) => {
    const shapeId = target.id;
    const newX = target.x;
    const newY = target.y;
  
    if (isMovingRef.current) {
      isMovingRef.current = false; // ✅ Reset after final shape in group
      // Do NOT pushToUndoStack again here
    }
  
    setPages((prevPages) => {
      const newPages = [...prevPages];
      newPages[activePage].shapes = newPages[activePage].shapes.map((shape) => {
        if (shape.id.toString() === shapeId) {
          return {
            ...shape,
            x: newX,
            y: newY,
          };
        }
        return shape;
      });
      return newPages;
    });
  };
  
  // Shape Transform End Handler
  const handleTransformEnd = (shapeId, updatedProps) => {
    setPages((prevPages) => {
      const newPages = [...prevPages];
      newPages[activePage].shapes = newPages[activePage].shapes.map((shape) => {
        if (shape.id.toString() === shapeId) {
          return {
            ...shape,
            ...updatedProps,
          };
        }
        return shape;
      });
      return newPages;
    });
  };

  // Save Canvas
  const saveStateToLocalStorage = () => {
    setIsLoading(true);  // Show the loader when saving
    const stateToSave = {
      pages,
      activePage,
      // Add any other state variables you want to save
    };
    localStorage.setItem('savedCanvasState', JSON.stringify(stateToSave));
    setIsLoading(false); // Hide the loader after saving
    alert('Your work has been saved.');
  };
  

  useEffect(() => {
    if (!initialState) {
      const savedState = localStorage.getItem('savedCanvasState');
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setPages(parsedState.pages);
        setActivePage(parsedState.activePage);
        // Set other state variables if needed
      }
    }
  }, [initialState]);

  // Save Canvas as Image
  const saveCanvas = () => {
    const uri = stageRef.current.toDataURL({ pixelRatio: 3 });
    const link = document.createElement('a');
    const filename = sanitizeFilename(exportFilename, 'png');
    link.download = filename;
    link.download = 'canvas.png';
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sanitizeFilename = (name, extension) => {
    // Remove invalid characters
    const sanitized = name.replace(/[^a-z0-9_-]/gi, '_');
    // Ensure the filename is not empty
    return sanitized ? `${sanitized}.${extension}` : `canvas.${extension}`;
  };
  

  // Determine Selection Types
  const selectedTextShapes = pages[activePage].shapes.filter(
    (shape) =>
      shape.type === 'text' && selectedShapeIds.includes(shape.id.toString())
  );

  const allSelectedAreText =
    selectedShapeIds.length > 0 &&
    selectedTextShapes.length === selectedShapeIds.length;

  const allSelectedAreShapes =
    selectedShapeIds.length > 0 &&
    pages[activePage].shapes
      .filter((shape) => selectedShapeIds.includes(shape.id.toString()))
      .every((shape) =>
        ['rect', 'roundedRect', 'circle', 'polygon', 'star', 'triangle', 'pentagon', 'hexagon', 'octagon'].includes(
          shape.type
        )
      );

  const allSelectedAreLines =
  selectedShapeIds.length > 0 &&
  pages[activePage].shapes
    .filter((shape) => selectedShapeIds.includes(shape.id.toString()))
    .every((shape) => shape.type === 'line');

  const allSelectedArePencilLines =
  selectedShapeIds.length > 0 &&
  pages[activePage].shapes
    .filter((shape) => selectedShapeIds.includes(shape.id.toString()))
    .every((shape) => shape.type === 'pencil'); 

  const allSelectedAreImages =
  selectedShapeIds.length > 0 &&
  pages[activePage].shapes
    .filter((shape) => selectedShapeIds.includes(shape.id.toString()))
    .every((shape) => shape.type === 'image');  
    

// Text properties input passing
useEffect(() => {
  if (allSelectedAreText && selectedTextShapes.length === 1) {
    const selectedTextShape = selectedTextShapes[0];
    setTextProperties({
      text: selectedTextShape.text,
      fontFamily: selectedTextShape.fontFamily,
      fontSize: selectedTextShape.fontSize,
      fill: selectedTextShape.fill,
      opacity: selectedTextShape.opacity,
      bold: selectedTextShape.bold,
      italic: selectedTextShape.italic,
      underline: selectedTextShape.underline,
    });
  }
}, [selectedShapeIds, allSelectedAreText, selectedTextShapes]);    

// Shape properties input passing
useEffect(() => {
  if (selectedShapeIds.length === 1) {
    const selectedShape = pages[activePage].shapes.find(
      (shape) => shape.id.toString() === selectedShapeIds[0]
    );
    if (selectedShape) {
      switch (selectedShape.type) {
        case 'rect':
        case 'roundedRect':
        case 'circle':
        case 'polygon':
        case 'star':
        case 'triangle':
        case 'pentagon':
        case 'hexagon':
        case 'octagon':
          setShapeProperties({
            strokeWidth: selectedShape.strokeWidth || 2,
            strokeColor: selectedShape.strokeColor || '#000000',
            fillColor: selectedShape.fillColor || '#FFFFFF',
            opacity: selectedShape.opacity || 1,
            cornerRadius: selectedShape.cornerRadius || 10,
            dashEnabled: selectedShape.dashEnabled || false,
            dash: selectedShape.dash || [10, 5],
            strokeEnabled: selectedShape.strokeEnabled !== undefined ? selectedShape.strokeEnabled : true,
            fillEnabled: selectedShape.fillEnabled !== undefined ? selectedShape.fillEnabled : true,
          });
          break;
        case 'line':
          setLineProperties({
            strokeWidth: selectedShape.strokeWidth || 2,
            strokeColor: selectedShape.strokeColor || '#000000',
            dashEnabled: selectedShape.dashEnabled || false,
            dash: selectedShape.dash || [10, 5],
          });
          break;
          case 'pencil':
            setPencilProperties({
              strokeWidth: selectedShape.strokeWidth || 2,
              strokeColor: selectedShape.strokeColor || '#000000',
              tension: selectedShape.tension || 1,
              dashEnabled: selectedShape.dashEnabled || false,
              dash: selectedShape.dash || [10, 5],
              opacity: selectedShape.opacity ?? 1, // ✅ ADD THIS LINE
            });
            break;          
        case 'text':
          setTextProperties({
            text: selectedShape.text || '',
            fontFamily: selectedShape.fontFamily || 'Arial',
            fontSize: selectedShape.fontSize || 20,
            fill: selectedShape.fill || '#000000',
            opacity: selectedShape.opacity || 1,
            bold: selectedShape.bold || false,
            italic: selectedShape.italic || false,
            underline: selectedShape.underline || false,
          });
          break;
        case 'image':
          setShapeProperties((prevProperties) => ({
            ...prevProperties,
            opacity: selectedShape.opacity || 1,
          }));
          break;
        default:
          break;
      }
    }
  }
}, [selectedShapeIds, pages, activePage]);


 // Add useEffect to log grid visibility changes
 useEffect(() => {
  console.log('isGridVisible:', isGridVisible);
}, [isGridVisible]);

useEffect(() => {
  console.log('isQuartersGridVisible:', isQuartersGridVisible);
}, [isQuartersGridVisible]);

useEffect(() => {
  console.log('isThirdsGridVisible:', isThirdsGridVisible);
}, [isThirdsGridVisible]);

  // Handle Paste from Clipboard and Internal Paste
  const handleContextMenuPaste = async () => {
    const fakeEvent = {
      preventDefault: () => {},
      target: { tagName: '', isContentEditable: false }
    };
    await handlePaste(fakeEvent);
    hideContextMenu();
  };
  
  

  // Handle Context Menu
  const handleContextMenu = (e) => {
    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    setContextMenuPosition({ x, y });
    setContextMenuVisible(true);
  };

  const hideContextMenu = () => {
    setContextMenuVisible(false);
  };

  // Add event listeners for paste and context menu
  useEffect(() => {    
    window.addEventListener('paste', handlePaste);
    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('click', hideContextMenu);

    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('click', hideContextMenu);
    };
  }, [pages, activePage, canPaste]);

  // Undo and Redo Stack Size Logger (Optional)
  useEffect(() => {
    console.log('Undo Stack Size:', undoStack.current.length);
    console.log('Redo Stack Size:', redoStack.current.length);
  }, [undoStack.current.length, redoStack.current.length]);

  const handleUploadTemplate = async (data) => {
    const formData = new FormData();
    formData.append('json', JSON.stringify({
      ...data,
      user: currentUser,
    }));
  
    try {
      const res = await fetch('https://edugroove-backend.onrender.com/api/upload-template', {
        method: 'POST',
        body: formData
      });
  
      const text = await res.text();
      let result;
  
      try {
        result = JSON.parse(text);
      } catch (e) {
        console.error('Non-JSON response:', text);
        alert('Upload failed: Non-JSON response');
        return;
      }
  
      if (res.ok) {
        alert(result.message || '✅ Template uploaded!');
        setShowUploadModal(false);
      } else {
        alert('❌ Upload failed: ' + (result.error || 'Unknown error'));
      }
    } catch (error) {
      alert('Server error uploading template.');
      console.error('Upload failed:', error);
    }
  };  
  
  
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://edugroove.com/edugroove_backend/get_categories.php')
      .then(res => res.json())
      .then(data => {
        if (Array.isArray(data)) {
          setCategories(data);
        }
      })
      .catch(err => {
        console.error('Failed to fetch categories', err);
      });
  }, []);

  
  
  // Render method
  return (
    <div
      className="main-app"
      style={{
        backgroundColor: workspaceBackgroundColor,
        backgroundImage: workspaceBackgroundImage ? `url(${workspaceBackgroundImage})` : 'none',
        backgroundSize: 'cover', // Ensures the image covers the entire area
        backgroundPosition: 'center', // Centers the image
        backgroundRepeat: 'no-repeat', // Prevents tiling
        transition: 'background-image 0.5s ease-in-out', // Smooth transition
      }}
    >
      <TopMenuBar
        undo={undo}
        redo={redo}
        deleteSelectedShapes={deleteSelectedShapes}
        selectedShapeIds={selectedShapeIds}
        copySelectedShapes={copySelectedShapes}
        pasteCopiedShapes={pasteCopiedShapes}
        canPaste={canPaste}
        bringForward={bringForward}
        sendBack={sendBack}
        isEditMode={isEditMode} // Passed prop
        toggleEditMode={toggleEditMode} // Passed prop
        areAllSelectedLocked={areAllSelectedLocked} // Passed prop
        lockSelectedShapes={lockSelectedShapes} // Passed prop
        copyToAllPages={copyToAllPages}
      />

      <Sidebar
        selectedTool={selectedTool}
        handleToolClick={handleToolClick}
        triggerImageUpload={triggerImageUpload}
        hoveredTool={hoveredTool}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        isSidebarCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        imageInputRef={imageInputRef}
        handleImageUpload={handleImageUpload}
      />

      <div
        className="workspace"
        style={{
          backgroundColor: workspaceBackgroundColor,
          backgroundImage: workspaceBackgroundImage ? `url(${workspaceBackgroundImage})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          transition: 'background-image 0.5s ease-in-out',
        }} 
        onMouseDown={handleCanvasMouseDownDrag}
        onMouseMove={handleCanvasMouseMoveDrag}
        onMouseUp={handleCanvasMouseUpDrag}
      >
        <CanvasArea
          pages={pages}
          activePage={activePage}
          setPages={setPages}
          selectedTool={selectedTool}
          selectedShapeIds={selectedShapeIds}
          setSelectedShapeIds={setSelectedShapeIds}
          selectionRect={selectionRect}
          setSelectionRect={setSelectionRect}
          isSelecting={isSelecting}
          setIsSelecting={setIsSelecting}
          stageRef={stageRef}
          transformerRef={transformerRef}
          panOffset={panOffset}
          scale={scale}
          drawing={drawing}
          setDrawing={setDrawing}
          handleStageMouseDown={handleStageMouseDown}
          handleStageMouseMove={handleStageMouseMove}
          handleStageMouseUp={handleStageMouseUp}
          isShiftPressed={isShiftPressed}
          isEditingText={isEditingText}
          editingTextPosition={editingTextPosition}
          editingTextId={editingTextId}
          editingTextValue={editingTextValue}
          updateTextContentHandler={updateTextContentHandler}
          setIsEditingText={setIsEditingText}
          textProperties={textProperties}
          handleShapeClick={handleShapeClick}
          handleTextDoubleClick={handleTextDoubleClick}
          handleShapeDragStart={handleShapeDragStart}
          handleShapeDragEnd={handleShapeDragEnd}
          handleTransformEnd={handleTransformEnd}
          isGridVisible={isGridVisible}
          setIsGridVisible={setIsGridVisible} // Pass setter
          isQuartersGridVisible={isQuartersGridVisible} // New prop
          setIsQuartersGridVisible={setIsQuartersGridVisible} // New prop
          isThirdsGridVisible={isThirdsGridVisible} // New prop
          setIsThirdsGridVisible={setIsThirdsGridVisible} // New prop
          isEditMode={isEditMode} // Passed prop
          pushToUndoStack={pushToUndoStack}
          isExporting={isExporting}
        />
      </div>

      <ToolPropertiesPanel
        isPropertiesCollapsed={isPropertiesCollapsed}
        togglePropertiesPanel={togglePropertiesPanel}
        selectedShapeIds={selectedShapeIds}
        allSelectedAreText={allSelectedAreText}
        allSelectedAreShapes={allSelectedAreShapes}
        allSelectedAreImages={allSelectedAreImages}
        shapeProperties={shapeProperties}        
        updateShapePropertiesHandler={updateShapePropertiesHandler}
        textProperties={textProperties}
        updateTextPropertiesHandler={updateTextPropertiesHandler}
        selectedTool={selectedTool}
        setSelectedShapeType={setSelectedShapeType}
        selectedShapeType={selectedShapeType}
        changeCanvasOrientation={changeCanvasOrientation}
        pages={pages}
        activePage={activePage}
        changeMood={changeMood}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        updateLinePropertiesHandler={updateLinePropertiesHandler}
        lineProperties={lineProperties}
        updatePencilPropertiesHandler={updatePencilPropertiesHandler}
        pencilProperties={pencilProperties}
        addBlankPage={addBlankPage}
        duplicateCurrentPage={duplicateCurrentPage}
        saveState={saveStateToLocalStorage}
        exportToPDF={exportToPDF}
        triggerFileInput={triggerFileInput}
        triggerImageUpload={triggerImageUpload}
        saveStateAsJSON={saveStateAsJSON}
        isGridVisible={isGridVisible} // Pass grid visibility
        setIsGridVisible={setIsGridVisible} // Pass setter
        isQuartersGridVisible={isQuartersGridVisible} // New prop
        setIsQuartersGridVisible={setIsQuartersGridVisible} // New prop
        isThirdsGridVisible={isThirdsGridVisible} // New prop
        setIsThirdsGridVisible={setIsThirdsGridVisible} // New prop
        finalizePropertyChange={pushToUndoStack}
        exportFilename={exportFilename} // Add this line
        setExportFilename={setExportFilename}
        allSelectedAreLines={allSelectedAreLines} // Add this line
        allSelectedArePencilLines={allSelectedArePencilLines}
        setShapeProperties={setShapeProperties}
        setShowImageLibraryModal={setShowImageLibraryModal}
        onSetSquareLogo={setSquareLogoPlaceholder}
        onSetLogo={setLogoPlaceholder}
        onUploadTemplateClick={() => setShowUploadModal(true)}

      />

      {isLoading && <LottieLoader message="Exporting to PDF" />}



      <input
        type="file"
        accept=".groove,.json" // 👈 now accepts both
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={loadStateFromJSON}
      />


      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={imageInputRef}
        onChange={handleImageUpload}
      />

      <PageTabs
        pages={pages}
        activePage={activePage}
        handlePageClick={handlePageClick}
        handleDeleteClick={handleDeleteClick}
      />

      {showImageLibraryModal && (
        <ImageLibraryModal
          onClose={() => setShowImageLibraryModal(false)}
          onImageSelect={handleImageSelect}
        />
      )}

      {showDeleteModal && (
        <DeleteModal deletePage={deletePage} closeModal={closeModal} />
      )}

      {showUploadModal && (
        <UploadTemplateModal
          onClose={() => setShowUploadModal(false)}
          onUpload={handleUploadTemplate}
          pages={pages}
          categories={categories}
          user={currentUser}
        />
      )}


      {showLastPageModal && <LastPageModal closeModal={closeModal} />}

      {/* Custom Context Menu */}
      {contextMenuVisible && (
        <div
          className="custom-context-menu"
          style={{
            top: contextMenuPosition.y,
            left: contextMenuPosition.x,
            position: 'absolute',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            zIndex: 1000,
            boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
          }}
        >
          <ul style={{ listStyle: 'none', margin: 0, padding: '5px 0' }}>
            <li
              onClick={() => {
                undo();
                hideContextMenu();
              }}
              style={{ padding: '5px 20px', cursor: 'pointer', color: '#000' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 10 }} />
              Undo
            </li>
            <li
              onClick={() => {
                redo();
                hideContextMenu();
              }}
              style={{ padding: '5px 20px', cursor: 'pointer', color: '#000' }}
            >
              <FontAwesomeIcon icon={faArrowRight} style={{ marginRight: 10 }} />
              Redo
            </li>
            <li
              onClick={() => {
                bringForward();
                hideContextMenu();
              }}
              style={{ padding: '5px 20px', cursor: 'pointer', color: '#000' }}
            >
              <FontAwesomeIcon icon={faArrowUp} style={{ marginRight: 10 }} />
              Bring Forward
            </li>
            <li
              onClick={() => {
                sendBack();
                hideContextMenu();
              }}
              style={{ padding: '5px 20px', cursor: 'pointer', color: '#000' }}
            >
              <FontAwesomeIcon icon={faArrowDown} style={{ marginRight: 10 }} />
              Send Back
            </li>
            <li
              onClick={() => {
                copySelectedShapes();
                hideContextMenu();
              }}
              style={{
                padding: '5px 20px',
                cursor: selectedShapeIds.length > 0 ? 'pointer' : 'not-allowed',
                color: selectedShapeIds.length > 0 ? '#000' : '#aaa',
              }}
            >
              <FontAwesomeIcon icon={faCopy} style={{ marginRight: 10 }} />
              Copy
            </li>
            <li
              onClick={handleContextMenuPaste}
              style={{
                padding: '5px 20px',
                cursor: 'pointer',
                color: '#000',
              }}              
            >

              <FontAwesomeIcon icon={faPaste} style={{ marginRight: 10 }} />
              Paste
            </li>
            <li
              onClick={() => {
                deleteSelectedShapes();
                hideContextMenu();
              }}
              style={{
                padding: '5px 20px',
                cursor: selectedShapeIds.length > 0 ? 'pointer' : 'not-allowed',
                color: selectedShapeIds.length > 0 ? '#000' : '#aaa',
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
              Delete
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};



export default MainApp;
